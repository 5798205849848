import { Helmet, HelmetProvider } from "react-helmet-async"
import { Outlet } from "react-router-dom"
import NavBar from "../../Components/C0217/Main/Navbar"
import css from '../../css/C0217/mainLayout.module.css'

import ACLogo from '../../global/C0217/tasteofasiaLogo.png'
import ChangeColor from "../../Components/ChangeColor"
import axios from "axios"
import { Cookies } from "react-cookie";
import { UserContext } from "../../Class/UserPara"
import { useContext, useEffect, useState } from "react"
import { use } from "i18next"
const cookies = new Cookies();


const C0217DashLayout = () => {
    const { userData, setUserData } = useContext(UserContext);
    const [ userToken, setUserToken ] = useState(cookies.get('token'));

    // useEffect(() => {
    //     getUserDataByToken()
    // }, [])

    useEffect(() => {
        console.log("Last Update: 15/11/2024 14:20:00" )
        getUserDataByToken()
    }, [userToken])

    const getUserDataByToken = async () => {
        let token = cookies.get('token') 

        if (token === undefined || token === null) {
            token = await getUserToken()
        }

        try {

            const getUserDataUrl = await axios.post(`${process.env.REACT_APP_API_URL}/c0217_vba/user/GetUserDataByToken`, {}, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            if (getUserDataUrl.status === 200) {
                setUserData(getUserDataUrl.data)
            } 

        } catch (error) {
            console.log(error)
            window.location.replace(`${process.env.REACT_APP_WEBSITE_URL}/Login/`);
        } finally {
            console.log("Get User Data")
        }
    }

    const getUserToken = async () => {
        return cookies.get('token')
    }

    
    const content = (
        <body>
            <HelmetProvider>
                <div>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Taste of Asia - Dashboard</title>
                    </Helmet>
                    <div className={css.DashLayout}>
                        <div className={css.logo}>
                            <img src={ACLogo} alt="TasteofAsia Logo" />
                        </div>

                        <div className={css.navbar}>
                            <NavBar />
                        </div>

                        <div className={css.userinfo}>
                            <ChangeColor />
                        </div>

                        <div className={css.Outlet}>
                            <Outlet />
                        </div>
                    </div>

                </div>
            </HelmetProvider>
        </body>
    )

    return content
}

export default C0217DashLayout