/* eslint-disable react/style-prop-object */
import { useMemo } from "react";
import css from "../../../css/C0217/DownloadReport.module.css"
import { IC0217DownloadReportProps, IC0217ShopList } from "../../../Interface/interface";

import foodpandaLogo from "../../../global/C0217/FoodPandaLogo.png"
import deliverooLogo from "../../../global/C0217/deliverooLogo.png"
import keetaLogo from "../../../global/C0217/KeetaLogo.png"

import cafe100 from '../../../global/C0217/cafe100.jpg'
import cha_thai from '../../../global/C0217/cha_thai.jpg'
import chicken_factory from '../../../global/C0217/chicken_factory.jpg'
import coco_fish from '../../../global/C0217/coco_fish.jpg'
import itamomo from '../../../global/C0217/itamama.jpg'
import itamama from '../../../global/C0217/itamama2.jpg'
import lime_fish from '../../../global/C0217/lime_fish.jpg'
import mama_bakery from '../../../global/C0217/mama_bakery.jpg'
import mc_dau from '../../../global/C0217/mc_dau.jpg'
import milk_cafe from '../../../global/C0217/milk_cafe.jpg'
import mizi from '../../../global/C0217/mizi.jpg'
import momo_classic from '../../../global/C0217/momo_classic.jpg'
import momoku from '../../../global/C0217/momoku.jpg'
import rud from '../../../global/C0217/rud.jpg'
import smile_bread from '../../../global/C0217/smile_bread.jpg'
import tao_yuen from '../../../global/C0217/tao_yuen.jpg'
import uuro from '../../../global/C0217/uuro.jpg'
import otherOption from '../../../global/C0217/Other.jpg'
import { useEffect, useState } from "react";
import axios from "axios";
import { Cookies } from "react-cookie";
import { use } from "i18next";
const cookies = new Cookies();

const BrandList = [
    { BrandName: "Café 100%", BrandImage: cafe100 },
    { BrandName: "Cha Thai", BrandImage: cha_thai },
    { BrandName: "Chicken Factory", BrandImage: chicken_factory },
    { BrandName: "Coco Fish", BrandImage: coco_fish },
    { BrandName: "Itamomo", BrandImage: itamomo },
    { BrandName: "Itamama", BrandImage: itamama },
    { BrandName: "Lime Fish", BrandImage: lime_fish },
    { BrandName: "Mama Bakery", BrandImage: mama_bakery },
    { BrandName: "MC Dau", BrandImage: mc_dau },
    { BrandName: "Milk Café", BrandImage: milk_cafe },
    { BrandName: "Mizi", BrandImage: mizi },
    { BrandName: "Momo Classic", BrandImage: momo_classic },
    { BrandName: "Momoku", BrandImage: momoku },
    { BrandName: "RUD", BrandImage: rud },
    { BrandName: "Smile Bread", BrandImage: smile_bread },
    { BrandName: "Tao Yuen", BrandImage: tao_yuen },
    { BrandName: "UURO", BrandImage: uuro },
    { BrandName: "Other", BrandImage: otherOption },
]


const ReportType = [
    { value: "Summary Report", displayName: "Summary Report (VBA Only)", LockFnc: true },
    //{ value: "Raw Data Report", displayName: "Raw Data Report", lockFnc: false },
    { value: "Raw Data Summary", displayName: "Sales Analysis Report", LockFnc: false },
    { value: "Bi-monthly Report For Bank-in Amount", displayName: "Bi-monthly Report For Bank-in Amount (VBA Only)", LockFnc: true },
    { value: "RAWData", displayName: "Raw Data", LockFnc: false },
]

const RawDataReportType = [
    { value: "Raw Data Summary", displayName: "Raw Data Summary" },
    { value: "Bi-monthly Report For Bank-in Amount", displayName: "Bi-monthly Report For Bank-in Amount" },
    { value: "Raw Date Report A", displayName: "Raw Date Report A" },
    { value: "Raw Date Report B", displayName: "Raw Date Report B" },
]

const BrandAndShopFilter: React.FC<IC0217DownloadReportProps> = ({
    setShowAlert,
    setAlertType,
    setAlertMessage,
    setLoading,
    setStage,
    isFilterData,
    handleInputChange,
    handleCheckboxChange,
    handleBrandSelection,
    selectedShops,
    setSelectedShops,
    handleShopListCheckboxChange,
    keywords,
    addKeywordInput,
    handleKeywordChange,
    handlePackageLoad,
    hasModifiedShops,
    setHasModifiedShops,
}) => {
    const [isdisplaymode, setDisplayMode] = useState<number>(3)
    const [packageName, setPackageName] = useState(""); // New state for package name
    const [keywordPackages, setKeywordPackages] = useState<{ name: string; keywords: string[] }[]>([]);

    const [shopdata, setShopData] = useState<IC0217ShopList[]>([])

    const [isshowshoplist, setShowShopList] = useState<boolean>(true)

    const [isSelectAllShop, setSelectAllShop] = useState<boolean>(false)

    useEffect(() => {
        getLoadShopList()

        const savedPackages = cookies.get("keywordPackages");
        if (savedPackages) {
            setKeywordPackages(savedPackages);
        }
    }, []);

    useEffect(() => {
        console.log("hasModifiedShops", hasModifiedShops)
        if (!hasModifiedShops) {
            LBSSelectAllShopsOn()
            setSelectAllShop(true)
        }

        //selectAllShopsOn()
    }, [shopdata])

    useEffect(() => {
        if (hasModifiedShops) {
            setSelectAllShop(true)
            selectAllShopsOn()
        }
    }, [isFilterData?.Brand])

    const switchFilterByList = (value: number) => {
        switch (value) {
            case 1:
                return "File Name"
            case 2:
                return "Billing Date"
            default:
                return ""
        }
    }

    const savePackageToCookies = () => {
        if (!packageName) {
            setShowAlert && setShowAlert(true);
            setAlertType && setAlertType("Warning");
            setAlertMessage && setAlertMessage("Please enter a package name before saving.");
            return;
        }

        // Check if a package with the same name exists
        const packageIndex = keywordPackages.findIndex(pkg => pkg.name === packageName);
        let updatedPackages;

        if (packageIndex !== -1) {
            // Update existing package
            updatedPackages = [...keywordPackages];
            updatedPackages[packageIndex] = { name: packageName, keywords: keywords ? [...keywords] : [] };
            setAlertMessage && setAlertMessage(`Package "${packageName}" has been updated successfully.`);
        } else {
            // Add a new package
            updatedPackages = [...keywordPackages, { name: packageName, keywords: keywords ? [...keywords] : [] }];
            setAlertMessage && setAlertMessage(`Package "${packageName}" has been saved successfully.`);
        }

        // Save the updated packages array to cookies
        cookies.set("keywordPackages", updatedPackages, { path: "/", expires: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000) });

        // Update state and reset package name
        setKeywordPackages(updatedPackages);
        setPackageName(""); // Clear package name input
        setShowAlert && setShowAlert(true);
        setAlertType && setAlertType("Success");
    };

    const removePackageAtCookiesList = () => {

        if (!packageName) {
            setShowAlert && setShowAlert(true);
            setAlertType && setAlertType("Warning");
            setAlertMessage && setAlertMessage("Please enter a package name before delete.");
            return;
        }

        // Filter out the package by name
        const updatedPackages = keywordPackages.filter(pkg => pkg.name !== packageName);

        // Update cookies
        cookies.set("keywordPackages", updatedPackages, { path: "/", expires: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000) });

        // Update state
        setKeywordPackages(updatedPackages);
        setShowAlert && setShowAlert(true);
        setAlertType && setAlertType("Success");
        setAlertMessage && setAlertMessage(`Package "${packageName}" has been deleted successfully.`);
    };

    const knownBrandNames = BrandList.map((brand) => brand.BrandName).filter((name) => name !== "Other");

    // // Save keywords to cookies
    // const SaveKeywordsToCookies = () => {
    //     cookies.set('keywords', keywords, { path: '/', expires: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000) });
    //     setShowAlert && setShowAlert(true);
    //     setAlertType && setAlertType("Success");
    //     setAlertMessage && setAlertMessage("Keywords have been saved to cookies successfully.");
    // };

    // Filter shops based on selected brands
    const filteredShops = useMemo(() => {
        // Return an empty array if no brands are selected at all
        if (!isFilterData?.Brand || isFilterData.Brand.length === 0) return shopdata;

        // Determine if "Other" is selected to include shops with unknown brands
        const isOtherSelected = isFilterData.Brand.includes("Other");

        return shopdata.filter(shop => {
            const isKnownBrand = knownBrandNames.includes(shop.shop_brand);
            const isSelectedBrand = isFilterData?.Brand && isFilterData.Brand.includes(shop.shop_brand);

            // Show shops if:
            // - The shop's brand is a selected brand, OR
            // - "Other" is selected and the brand is unknown
            return isSelectedBrand || (isOtherSelected && !isKnownBrand);
        });

    }, [isFilterData?.Brand, shopdata]);

    const getLoadShopList = async () => {
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/c0217_vba/order/LoadShopList`, {}, {})

            if (res.status === 200) {
                setShopData(res.data['Shop list'])
            }
        } catch (error) {
            console.log(error)
        } finally {
        }
    }

    const routerPlatform = (platform: string) => {
        switch (platform) {
            case "foodpanda":
                return foodpandaLogo
            case "deliveroo":
                return deliverooLogo
            case "keeta":
                return keetaLogo
            default:
                return ""
        }
    }

    const LBSSelectAllShopsOn = () => {
        setSelectedShops && setSelectedShops(prevSelectedShops => {
            const allSelected = new Set(prevSelectedShops); // Keep existing selections
            filteredShops.forEach((shop) => allSelected.add(shop.shop_id)); // Add each `shop_id` from `filteredShops`
            return allSelected;
        });
    };

    const selectAllShopsOn = () => {
        setSelectedShops && setSelectedShops(prevSelectedShops => {
            const allSelected = new Set(prevSelectedShops); // Keep existing selections
            filteredShops.forEach((shop) => allSelected.add(shop.shop_id)); // Add each `shop_id` from `filteredShops`
            return allSelected;
        });
        setHasModifiedShops && setHasModifiedShops(true);
    };

    const selectAllShopsOff = () => {
        setSelectedShops && setSelectedShops(prevSelectedShops => {
            const allSelected = new Set(prevSelectedShops); // Keep existing selections
            filteredShops.forEach((shop) => allSelected.delete(shop.shop_id)); // Add each `shop_id` from `filteredShops`
            return allSelected;
        });
        setHasModifiedShops && setHasModifiedShops(true);
    };

    const handlePackageLoadProccess = (packname: string, packageKeywords: string[]) => {
        setPackageName(packname)
        handlePackageLoad && handlePackageLoad(packageKeywords)
    }

    const GoToNextStage = () => {

        console.log(isFilterData?.ReportType)
        console.log(selectedShops?.size)

        if (selectedShops?.size === 0 || selectedShops === undefined) {
            setShowAlert && setShowAlert(true);
            setAlertType && setAlertType("Warning");
            setAlertMessage && setAlertMessage("Please select at least one shop to proceed.");
            return;
        }

        if (isFilterData?.ReportType === "RAWData") {
            if (isFilterData?.OrderCodeKeyword === null || isFilterData?.OrderCodeKeyword.length <= 2) {
                setShowAlert && setShowAlert(true);
                setAlertType && setAlertType("Warning");
                setAlertMessage && setAlertMessage("Please enter Order Code Keywords for RAWData Report.");
                return;
            }
        }

        if (isFilterData?.ReportType === "Raw Data Summary") {
            if (selectedShops?.size >= 21) {
                setShowAlert && setShowAlert(true);
                setAlertType && setAlertType("Warning");
                setAlertMessage && setAlertMessage("Please select less than 20 shops for Raw Data Summary Report.");
                return;
            }
        }

        setStage && setStage(90)
    }

    const switchStartWith = (value: number) => {
        if (value === 1) {
            return true
        } else {
            return false
        }
    }


    function SearchShopFc() {
        const input = document.getElementById("SearchShopTxt") as HTMLInputElement;
        const filter = input.value.toUpperCase();
        const table = document.getElementById("ShopListTable") as HTMLTableElement;
        const tr = table.getElementsByTagName("tr");

        for (let i = 0; i < tr.length; i++) {
            const tdShopCode = tr[i].getElementsByTagName("td")[1];
            const tdShopName = tr[i].getElementsByTagName("td")[2];
            const tdShopOutlet = tr[i].getElementsByTagName("td")[3];
            const tdShopRelated = tr[i].getElementsByTagName("td")[4];
            if (tdShopCode || tdShopName || tdShopOutlet || tdShopRelated) {
                const txtShopCodeValue = tdShopCode.textContent || tdShopCode.innerText;
                const txtShopNameValue = tdShopName.textContent || tdShopName.innerText;
                const txtShopOutletValue = tdShopOutlet.textContent || tdShopOutlet.innerText;
                const txtShopRelatedValue = tdShopRelated.textContent || tdShopRelated.innerText;
                // Check if either Staff ID or Username matches the filter
                if (txtShopCodeValue.toUpperCase().indexOf(filter) > -1 ||
                    txtShopNameValue.toUpperCase().indexOf(filter) > -1 ||
                    txtShopOutletValue.toUpperCase().indexOf(filter) > -1 ||
                    txtShopRelatedValue.toUpperCase().indexOf(filter) > -1) {
                    tr[i].style.display = ""; // Show the row if match is found
                } else {
                    tr[i].style.display = "none"; // Hide the row if no match is found
                }
            }
        }
    }

    const switchdiffShop = (shopname: string) => {
        selectAllShopsOff()
        setSelectAllShop(false)
        handleBrandSelection && handleBrandSelection(shopname)
    }

    const content = (
        <>
            {isdisplaymode === 1 && (
                <section className={css.MainSection}>

                    <div className={css.ShowFilterStatusDiv}>
                        <div className={css.EachFilterItem}>
                            <label>Start Date: </label>
                            <p>{isFilterData?.StartDate}</p>
                        </div>

                        <div className={css.EachFilterItem}>
                            <label>End Date: </label>
                            <p>{isFilterData?.EndDate}</p>
                        </div>

                        {isFilterData?.DateFilterBy && isFilterData?.DateFilterBy !== null && (
                            <div className={css.EachFilterItem}>
                                <label>Date Filter By: </label>
                                <p>{switchFilterByList(isFilterData?.DateFilterBy)}</p>
                            </div>
                        )}


                        {isFilterData?.PlatformType && isFilterData?.PlatformType !== null && (
                            <div className={css.EachFilterItem}>
                                <label>Platform Type: </label>
                                {isFilterData?.PlatformType.map((item, index) => (
                                    <img key={index} src={routerPlatform(item)} alt={item} />
                                ))}
                            </div>
                        )}

                    </div>

                    <div className={css.BrandFilterDiv}>
                        <div className={css.FilterTitleDiv}>
                            <p>Brand Filter</p>
                        </div>

                        <div className={css.BrandListDiv}>
                            {BrandList.map((item, index) => (
                                <div
                                    key={index}
                                    className={css.EachBrandDiv}
                                    style={{
                                        border: isFilterData?.Brand?.includes(item.BrandName)
                                            ? "3px solid blue"
                                            : "1px solid black",
                                    }}
                                    onClick={() => switchdiffShop(item.BrandName)}
                                >
                                    <img src={item.BrandImage} alt={item.BrandName} />
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className={css.ShopTableDiv}>
                        <div className={css.FilterTitleDiv} onClick={() => setShowShopList(!isshowshoplist)}>
                            {selectedShops && selectedShops?.size > 0 ? (
                                <>
                                    <p>{`Shop List ( ${selectedShops?.size} )`}</p>
                                    <i className="fi fi-bs-chevron-double-down" />
                                </>
                            ) : (
                                <>
                                    <p>Shop List</p>
                                    <i className="fi fi-bs-chevron-double-down" />
                                </>
                            )}


                        </div>

                        {isshowshoplist && (
                            <>
                                <input type="text" id="SearchShopTxt" onKeyUp={SearchShopFc} className={css.SeachShopText} placeholder="Search for anything" ></input>

                                <div className={css.ShopListButtonDiv}>
                                    <button
                                        style={{ backgroundColor: '#4CAF50' }}
                                        className={css.SelectAllButton}
                                        onClick={selectAllShopsOn}
                                    >
                                        <p>Select All Table Shops</p>
                                    </button>

                                    <button
                                        style={{ backgroundColor: '#af4c4c' }}
                                        className={css.SelectAllButton}
                                        onClick={selectAllShopsOff}
                                    >
                                        <p>Remove All Table Shops</p>
                                    </button>
                                </div>


                                <div className={`${css.ShopList} ${isshowshoplist ? css.ShowShopList : ""}`}>
                                    <table className={css.ShopTable} id="ShopListTable">
                                        <tr>
                                            <th style={{ width: '20px' }}>Action</th>
                                            <th>Shop Code</th>
                                            <th>Shop Name</th>
                                            <th>Shop Outlet</th>
                                            <th>Related Shop</th>
                                        </tr>
                                        {filteredShops.map(shop => (
                                            <tr key={shop.shop_id}>
                                                <td style={{ textAlign: 'center' }}>
                                                    <input
                                                        type="checkbox"
                                                        checked={selectedShops && selectedShops.has(shop.shop_id)}
                                                        onChange={(e) => handleShopListCheckboxChange && handleShopListCheckboxChange(e, shop.shop_id)}
                                                    />
                                                </td>
                                                <td>{shop.shop_foodpanda_code1}</td>
                                                <td>{shop.shop_brand}</td>
                                                <td>{shop.shop_address}</td>
                                                <td>{shop.shop_code}</td>
                                            </tr>
                                        ))}
                                    </table>
                                </div>
                            </>
                        )}

                    </div>

                    <div className={css.OutputOption}>
                        <div className={css.FilterTitleDiv}>
                            <p>Output Option</p>
                        </div>

                        <div className={css.ReportTypeDiv}>

                            <div className={css.baseInfoItem}>
                                <label>Report Type</label>
                                <select name="ReportType" value={isFilterData?.ReportType ?? ''} onChange={handleInputChange}>
                                    {ReportType.map((item, index) => (
                                        <option key={index} value={item.value} disabled={item.LockFnc}>{item.displayName}</option>
                                    ))}
                                </select>
                            </div>

                            {isFilterData?.ReportType === "Raw Data Report" && (
                                <div className={css.baseInfoItem}>
                                    <fieldset className={css.TypeFieldset}>
                                        <legend>Please confirm raw data report contain as below</legend>
                                        {RawDataReportType.map((RDRT, index) => (
                                            <div key={RDRT.value} className={css.ProductTypeFieldset}>
                                                <input
                                                    type="checkbox"
                                                    id={RDRT.value}
                                                    name="RawDataReportType"
                                                    value={RDRT.value}
                                                    checked={isFilterData?.RawDataReportType?.includes(RDRT.value) || false}
                                                    onChange={(e) => handleCheckboxChange && handleCheckboxChange(e, 'RawDataReportType')}
                                                />
                                                <label htmlFor={RDRT.value} className={css.Typelable}>
                                                    {RDRT.displayName}
                                                </label>
                                            </div>
                                        ))}
                                    </fieldset>
                                </div>
                            )}
                        </div>

                        <div className={css.OptionLineDiv} />

                        <div className={css.KeywordsDiv}>

                            <div className={css.PackageNameDiv}>
                                <input
                                    type="text"
                                    placeholder="Enter Package Name"
                                    value={packageName}
                                    onChange={(e) => setPackageName(e.target.value)}
                                    className={css.PackageNameInput}
                                />
                                <button
                                    type="button"
                                    className={css.SavePackageButton}
                                    onClick={savePackageToCookies}
                                >
                                    <i className="fi fi-rs-disk" />
                                    <p>Save Keywords As A Package</p>
                                </button>
                                <button
                                    type="button"
                                    className={css.SavePackageButton}
                                    onClick={removePackageAtCookiesList}
                                >
                                    <i className="fi fi-rs-trash" />
                                    <p>Delete Package</p>
                                </button>
                            </div>

                            <fieldset className={css.KeywordPackageFieldset}>
                                <legend>Saved Keyword Packages</legend>
                                {keywordPackages.map((pkg, index) => (
                                    <button key={index} onClick={() => handlePackageLoadProccess(pkg.name, pkg.keywords)}>
                                        <p>{pkg.name}</p>
                                    </button>


                                ))}
                            </fieldset>


                            <fieldset className={css.KeyWordsFieldset}>
                                <legend>Please Add Keywords as below</legend>

                                {/* Render input for each keyword */}
                                {keywords && keywords.map((keyword, index) => (
                                    <input
                                        key={index}
                                        type="text"
                                        className={css.KeywordInput}
                                        placeholder={`Keyword ${index + 1}`}
                                        value={keyword}
                                        onChange={(e) => handleKeywordChange && handleKeywordChange(index, e.target.value)}
                                    />
                                ))}

                                {/* Button to add new keyword input */}
                                <button
                                    type="button"
                                    className={css.AddKeywordButton}
                                    onClick={addKeywordInput}
                                >
                                    <i className="fi fi-rr-multiple" />
                                    <p>More Keywords</p>
                                </button>
                            </fieldset>
                        </div>

                    </div>

                    <div className={css.ControlBtnDiv}>

                        <div className={css.ControlBtn}>
                            <button className={css.SelectionBackBtn} onClick={() => setStage && setStage(0)}>
                                <i className="fi fi-br-left" />
                                Back
                            </button>
                        </div>

                        <div className={css.ControlBtn}>
                            <button className={css.SelectionBtn} onClick={() => setStage && setStage(1)}>
                                <i className="fi fi-rr-print" />
                                Next
                            </button>
                        </div>

                    </div>
                </section>
            )}

            {isdisplaymode === 2 && (
                <section className={css.MainSection}>

                    <div className={css.ShowFilterStatusDiv}>
                        <div className={css.EachFilterItem}>
                            <label>Start Date: </label>
                            <p>{isFilterData?.StartDate}</p>
                        </div>

                        <div className={css.EachFilterItem}>
                            <label>End Date: </label>
                            <p>{isFilterData?.EndDate}</p>
                        </div>

                        {isFilterData?.DateFilterBy && isFilterData?.DateFilterBy !== null && (
                            <div className={css.EachFilterItem}>
                                <label>Date Filter By: </label>
                                <p>{switchFilterByList(isFilterData?.DateFilterBy)}</p>
                            </div>
                        )}


                        {isFilterData?.PlatformType && isFilterData?.PlatformType !== null && (
                            <div className={css.EachFilterItem}>
                                <label>Platform Type: </label>
                                {isFilterData?.PlatformType.map((item, index) => (
                                    <img key={index} src={routerPlatform(item)} alt={item} />
                                ))}
                            </div>
                        )}

                    </div>

                    <div className={css.P2MainDiv}>
                        <div className={css.P2FilterDiv}>
                            <div className={css.BrandFilterDiv}>
                                <div className={css.FilterTitleDiv}>
                                    <p>Brand Filter</p>
                                </div>

                                <div className={css.BrandListDiv}>
                                    {BrandList.map((item, index) => (
                                        <div
                                            key={index}
                                            className={css.EachBrandDiv2}
                                            style={{
                                                border: isFilterData?.Brand?.includes(item.BrandName)
                                                    ? "3px solid blue"
                                                    : "1px solid black",
                                            }}
                                            onClick={() => handleBrandSelection && handleBrandSelection(item.BrandName)}
                                        >
                                            <img src={item.BrandImage} alt={item.BrandName} />
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div className={css.OutputOption}>
                                <div className={css.FilterTitleDiv}>
                                    <p>Output Option</p>
                                </div>

                                <div className={css.ReportTypeDiv}>

                                    <div className={css.baseInfoItem}>
                                        <label>Report Type</label>
                                        <select name="ReportType" value={isFilterData?.ReportType ?? ''} onChange={handleInputChange}>
                                            {ReportType.map((item, index) => (
                                                <option key={index} value={item.value} disabled={item.LockFnc}>{item.displayName}</option>
                                            ))}
                                        </select>
                                    </div>

                                    {isFilterData?.ReportType === "Raw Data Report" && (
                                        <div className={css.baseInfoItem}>
                                            <fieldset className={css.TypeFieldset}>
                                                <legend>Please confirm raw data report contain as below</legend>
                                                {RawDataReportType.map((RDRT, index) => (
                                                    <div key={RDRT.value} className={css.ProductTypeFieldset}>
                                                        <input
                                                            type="checkbox"
                                                            id={RDRT.value}
                                                            name="RawDataReportType"
                                                            value={RDRT.value}
                                                            checked={isFilterData?.RawDataReportType?.includes(RDRT.value) || false}
                                                            onChange={(e) => handleCheckboxChange && handleCheckboxChange(e, 'RawDataReportType')}
                                                        />
                                                        <label htmlFor={RDRT.value} className={css.Typelable}>
                                                            {RDRT.displayName}
                                                        </label>
                                                    </div>
                                                ))}
                                            </fieldset>
                                        </div>
                                    )}
                                </div>

                                <div className={css.OptionLineDiv} />

                                <div className={css.KeywordsDiv}>

                                    <div className={css.ExtensionDiv}>
                                        <div className={css.ProductTypeFieldset}>
                                            <input
                                                type="checkbox"
                                                name="ExtensionKeyword"
                                                // value={isFilterData && isFilterData?.ExtensionKeyword}
                                                checked={isFilterData?.ExtensionKeyword === true || false}
                                                onChange={(e) => handleCheckboxChange && handleCheckboxChange(e, 'ExtensionKeyword')}
                                            />
                                            <label className={css.Typelable}>
                                                Extension Report Keywords
                                            </label>
                                        </div>

                                        {isFilterData?.ExtensionKeyword && (
                                            <>
                                                <div className={css.ExtensionKeyInputDiv2}>
                                                    <div className={css.baseInfoItem2}>
                                                        <label>File Name Keywords</label>
                                                        <input
                                                            type="text"
                                                            name="FileNameKeyword"
                                                            value={isFilterData?.FileNameKeyword ?? ''}
                                                            onChange={handleInputChange}
                                                        />
                                                    </div>

                                                    <div className={css.baseInfoItem2}>
                                                        <label>Order Code Keywords</label>
                                                        <input
                                                            type="text"
                                                            name="OrderCodeKeyword"
                                                            value={isFilterData?.OrderCodeKeyword ?? ''}
                                                            onChange={handleInputChange}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>


                                </div>

                            </div>

                        </div>

                        <div className={css.P2ShopListDiv}>
                            <div className={css.ShopTableDiv}>
                                <div className={css.FilterTitleDiv}>
                                    {selectedShops && selectedShops?.size > 0 ? (
                                        <>
                                            <p>{`Shop List ( ${selectedShops?.size} )`}</p>
                                        </>
                                    ) : (
                                        <>
                                            <p>Shop List</p>
                                        </>
                                    )}


                                </div>

                                {isshowshoplist && (
                                    <>
                                        <input type="text" id="SearchShopTxt" onKeyUp={SearchShopFc} className={css.SeachShopText2} placeholder="Search for anything" ></input>

                                        <div className={css.ShopListButtonDiv}>
                                            <button
                                                style={{ backgroundColor: '#4CAF50' }}
                                                className={css.SelectAllButton}
                                                onClick={selectAllShopsOn}
                                            >
                                                <p>Select All Table Shops</p>
                                            </button>

                                            <button
                                                style={{ backgroundColor: '#af4c4c' }}
                                                className={css.SelectAllButton}
                                                onClick={selectAllShopsOff}
                                            >
                                                <p>Remove All Table Shops</p>
                                            </button>
                                        </div>


                                        <div className={`${css.ShopList2} ${isshowshoplist ? css.ShowShopList : ""}`}>
                                            <div className={css.ShopTableContainer}>
                                                <table className={css.ShopTable2} id="ShopListTable">
                                                    <tr>
                                                        <th style={{ width: '20px' }}>Action</th>
                                                        <th>Shop Code</th>
                                                        <th>Shop Name</th>
                                                        <th>Shop Outlet</th>
                                                        <th>Related Shop</th>
                                                    </tr>
                                                    {filteredShops.map(shop => (
                                                        <tr key={shop.shop_id}>
                                                            <td style={{ textAlign: 'center' }}>
                                                                <input
                                                                    type="checkbox"
                                                                    checked={selectedShops && selectedShops.has(shop.shop_id)}
                                                                    onChange={(e) => handleShopListCheckboxChange && handleShopListCheckboxChange(e, shop.shop_id)}
                                                                />
                                                            </td>
                                                            <td>{shop.shop_foodpanda_code1}</td>
                                                            <td>{shop.shop_brand}</td>
                                                            <td>{shop.shop_address}</td>
                                                            <td>{shop.shop_code}</td>
                                                        </tr>
                                                    ))}
                                                </table>
                                            </div>
                                        </div>
                                    </>
                                )}

                            </div>

                        </div>

                    </div>

                    <div className={css.ControlBtnDiv2}>
                        <div className={css.ControlBtnDiv}>

                            <div className={css.ControlBtn}>
                                <button className={css.SelectionBackBtn} onClick={() => setStage && setStage(0)}>
                                    <i className="fi fi-br-left" />
                                    Back
                                </button>
                            </div>

                            <div className={css.ControlBtn}>
                                <button className={css.SelectionBtn} onClick={() => setStage && setStage(2)}>
                                    <i className="fi fi-rr-print" />
                                    Next
                                </button>
                            </div>

                        </div>
                    </div>

                </section>
            )}

            {isdisplaymode === 3 && (
                <section className={css.MainSection}>

                    <div className={css.ShowFilterStatusDiv}>
                        <div className={css.EachFilterItem}>
                            <label>Start Date: </label>
                            <p>{isFilterData?.StartDate}</p>
                        </div>

                        <div className={css.EachFilterItem}>
                            <label>End Date: </label>
                            <p>{isFilterData?.EndDate}</p>
                        </div>

                        {isFilterData?.DateFilterBy && isFilterData?.DateFilterBy !== null && (
                            <div className={css.EachFilterItem}>
                                <label>Date Filter By: </label>
                                <p>{switchFilterByList(isFilterData?.DateFilterBy)}</p>
                            </div>
                        )}


                        {isFilterData?.PlatformType && isFilterData?.PlatformType !== null && (
                            <div className={css.EachFilterItem}>
                                <label>Platform Type: </label>
                                {isFilterData?.PlatformType.map((item, index) => (
                                    <img key={index} src={routerPlatform(item)} alt={item} />
                                ))}
                            </div>
                        )}

                    </div>

                    <div className={css.D3_P2MainDiv}>

                        <div className={css.D3_P2FilterDiv}>
                            <div className={css.D3_BrandFilterDiv}>
                                <div className={css.FilterTitleDiv}>
                                    <p>Brand Filter</p>
                                </div>

                                <div className={css.BrandListDiv}>
                                    {BrandList.map((item, index) => (
                                        <div
                                            key={index}
                                            className={css.EachBrandDiv2}
                                            style={{
                                                border: isFilterData?.Brand?.includes(item.BrandName)
                                                    ? "3px solid #292a2d"
                                                    : "1px solid black",
                                            }}
                                            onClick={() => switchdiffShop(item.BrandName)}
                                        >
                                            <img src={item.BrandImage} alt={item.BrandName} />
                                        </div>
                                    ))}
                                </div>
                            </div>

                        </div>

                        <div className={css.D3_P2ShopListDiv}>
                            <div className={css.ShopTableDiv}>
                                <div className={css.FilterTitleDiv}>
                                    {selectedShops && selectedShops?.size > 0 ? (
                                        <>
                                            <p>{`Shop List ( ${selectedShops?.size} )`}</p>
                                        </>
                                    ) : (
                                        <>
                                            <p>Shop List</p>
                                        </>
                                    )}


                                </div>

                                {isshowshoplist && (
                                    <>
                                        <input type="text" id="SearchShopTxt" onKeyUp={SearchShopFc} className={css.SeachShopText2} placeholder="Search for anything" ></input>

                                        <div className={css.ShopListButtonDiv}>
                                            {/* <button
                                                style={{ backgroundColor: '#4CAF50' }}
                                                className={css.SelectAllButton}
                                                onClick={selectAllShopsOn}
                                            >
                                                <p>Select All Table Shops</p>
                                            </button>

                                            <button
                                                style={{ backgroundColor: '#af4c4c' }}
                                                className={css.SelectAllButton}
                                                onClick={selectAllShopsOff}
                                            >
                                                <p>Remove All Table Shops</p>
                                            </button> */}
                                            <input
                                                type="checkbox"
                                                id="selectAllShops"
                                                name="selectAllShops"
                                                checked={isSelectAllShop}
                                                onChange={() => {
                                                    if (isSelectAllShop) {
                                                        selectAllShopsOff(); // Deselect all shops
                                                    } else {
                                                        selectAllShopsOn(); // Select all shops
                                                    }
                                                    setSelectAllShop(!isSelectAllShop); // Toggle the state
                                                }}
                                            />
                                            <label htmlFor="selectAllShops">Select All Table Shops</label>
                                        </div>


                                        <div className={`${css.ShopList2} ${isshowshoplist ? css.ShowShopList : ""}`}>
                                            <div className={css.ShopTableContainer}>
                                                <table className={css.ShopTable2} id="ShopListTable">
                                                    <tr>
                                                        <th style={{ width: '20px' }}>Action</th>
                                                        <th>Shop Code</th>
                                                        <th>Shop Name</th>
                                                        <th>Shop Outlet</th>
                                                        <th>Related Shop</th>
                                                    </tr>
                                                    {filteredShops.map(shop => (
                                                        <tr key={shop.shop_id}>
                                                            <td style={{ textAlign: 'center' }}>
                                                                <input
                                                                    type="checkbox"
                                                                    checked={selectedShops && selectedShops.has(shop.shop_id)}
                                                                    onChange={(e) => handleShopListCheckboxChange && handleShopListCheckboxChange(e, shop.shop_id)}
                                                                />
                                                            </td>
                                                            <td>{shop.shop_foodpanda_code1}</td>
                                                            <td>{shop.shop_brand}</td>
                                                            <td>{shop.shop_address}</td>
                                                            <td>{shop.shop_code}</td>
                                                        </tr>
                                                    ))}
                                                </table>
                                            </div>
                                        </div>
                                    </>
                                )}

                            </div>

                        </div>

                        <div className={css.D3_ReportDiv}>

                            <div className={css.D3_OutputOption}>

                                <div className={css.FilterTitleDiv}>
                                    <p>Output Option</p>
                                </div>

                                <div className={css.D3_grapTable}>

                                    <div className={css.D3_ReportTypeDiv}>

                                        <div className={css.baseInfoItem}>
                                            <label>Report Type</label>
                                            <select name="ReportType" value={isFilterData?.ReportType ?? ''} onChange={handleInputChange}>
                                                {ReportType.map((item, index) => (
                                                    <option key={index} value={item.value} disabled={item.LockFnc}>{item.displayName}</option>
                                                ))}
                                            </select>
                                        </div>

                                        {isFilterData?.ReportType === "Raw Data Report" && (
                                            <div className={css.baseInfoItem}>
                                                <fieldset className={css.TypeFieldset}>
                                                    <legend>Please confirm raw data report contain as below</legend>
                                                    {RawDataReportType.map((RDRT, index) => (
                                                        <div key={RDRT.value} className={css.ProductTypeFieldset}>
                                                            <input
                                                                type="checkbox"
                                                                id={RDRT.value}
                                                                name="RawDataReportType"
                                                                value={RDRT.value}
                                                                checked={isFilterData?.RawDataReportType?.includes(RDRT.value) || false}
                                                                onChange={(e) => handleCheckboxChange && handleCheckboxChange(e, 'RawDataReportType')}
                                                            />
                                                            <label htmlFor={RDRT.value} className={css.Typelable}>
                                                                {RDRT.displayName}
                                                            </label>
                                                        </div>
                                                    ))}
                                                </fieldset>
                                            </div>
                                        )}

                                    </div>

                                    <div className={css.D3_OptionLineDiv} />

                                    <div className={css.D3_KeywordsDiv}>

                                        <div className={css.ExtensionDiv}>
                                            <div className={css.ProductTypeFieldset}>
                                                <input
                                                    type="checkbox"
                                                    name="ExtensionKeyword"
                                                    // value={isFilterData && isFilterData?.ExtensionKeyword}
                                                    checked={isFilterData?.ExtensionKeyword === true || false}
                                                    onChange={(e) => handleCheckboxChange && handleCheckboxChange(e, 'ExtensionKeyword')}
                                                />
                                                <label className={css.Typelable}>
                                                    Extension Report Keywords
                                                </label>
                                            </div>

                                            {isFilterData?.ExtensionKeyword && (
                                                <>
                                                    <div className={css.ExtensionKeyInputDiv2}>
                                                        <div className={css.baseInfoItem2}>
                                                            <label>File Name Keywords</label>
                                                            <input
                                                                type="text"
                                                                name="FileNameKeyword"
                                                                value={isFilterData?.FileNameKeyword ?? ''}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>

                                                        <div className={css.baseInfoItem2}>
                                                            <div className={css.SWOCDiv}>
                                                                <label>Order Code Keywords</label>

                                                                <label className={css.SWOClable}>(</label>
                                                                <input
                                                                    type="checkbox"
                                                                    name="OrderCodeSWCheck"
                                                                    // value={isFilterData && isFilterData?.ExtensionKeyword}
                                                                    checked={switchStartWith(isFilterData?.OrderCodeSWCheck)}
                                                                    onChange={(e) => handleCheckboxChange && handleCheckboxChange(e, 'OrderCodeSWCheck')}
                                                                />
                                                                <label className={css.SWOClable}>
                                                                    Start With Order Code)
                                                                </label>
                                                            </div>

                                                            <input
                                                                type="text"
                                                                name="OrderCodeKeyword"
                                                                value={isFilterData?.OrderCodeKeyword ?? ''}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </div>

                                        {keywords && keywords.length > 0 && (
                                            <div className={css.D3_KeyWordListDiv}>
                                                <fieldset className={css.D3_KeywordPackageFieldset}>
                                                    <legend>Saved Keyword Packages</legend>
                                                    {keywords && keywords.map((keyword, index) => (
                                                        <p>{keyword}</p>
                                                    ))}
                                                </fieldset>
                                            </div>
                                        )}

                                        <div className={css.D3_KeyWordControlBtn}>
                                            <div className={css.ControlBtn}>
                                                <button className={css.AddFilterBtn} onClick={() => setStage && setStage(2)}>
                                                    <i className="fi fi-rr-search-alt" />
                                                    More Keywords
                                                </button>
                                            </div>
                                        </div>


                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>

                    <div className={css.ControlBtnDiv2}>
                        <div className={css.ControlBtnDiv}>

                            <div className={css.ControlBtn}>
                                <button className={css.SelectionBackBtn} onClick={() => setStage && setStage(0)}>
                                    <i className="fi fi-br-left" />
                                    Back
                                </button>
                            </div>

                            <div className={css.ControlBtn}>
                                <button className={css.SelectionBtn} onClick={GoToNextStage}>
                                    <i className="fi fi-rr-file" />
                                    Generate Report
                                </button>
                            </div>

                        </div>
                    </div>

                </section>
            )}
        </>
    )
    return content
}

export default BrandAndShopFilter;