import { useEffect, useState } from "react";
import { c0211ProductProps, IC0211Product, Ic0211ProductSKU, IC0211ProductSKURef, IC0211ProductType, IC0211SKUCode } from "../../../Interface/interface"
import AlertMessage from "../../LoadingBar/AlertMessage";
import DeafultLoadingBar from "../../LoadingBar/DefaultLoadingBar";

import css from '../../../css/C0211/EditProduct.module.css'
import { set } from "rsuite/esm/utils/dateUtils";
import { Cookies } from "react-cookie";
import axios from "axios";

import DefaultProductImg from '../../../global/C0211/LogoBW.png'
const cookies = new Cookies();


const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB
const C0211EditProductSKUDetail: React.FC<c0211ProductProps> = ({ isProductID, setProductID, isProductSKUID, setProductSKUID, isProductSKUCode, setProductSKUCode, setEditMode }) => {

    const [isloading, setLoading] = useState<boolean>(false);
    const [isShowAlert, setShowAlert] = useState<boolean>(false);
    const [isAlertType, setAlertType] = useState<string>('Info');
    const [isAlertMessage, setAlertMessage] = useState<string>('');
    const [isshowuploadinfo, setShowUploadInfo] = useState<boolean>(false);

    const [ismode, setMode] = useState<number>(1);

    const [fileData, setFileData] = useState<{ [key: string]: { file: File | null; previewUrl: string | null } }>({
        fileInput1: { file: null, previewUrl: null },
        fileInput2: { file: null, previewUrl: null },
    });

    const [typedata, setTypeData] = useState<IC0211ProductType[]>([]);
    const [data, setData] = useState<Ic0211ProductSKU>({
        Product_Main_ID: null,
        Product_SKU_ID: null,
        Product_Name: '',
        Product_main_Description: '',
        Product_main_Image: null,
        Product_main_Status: '',
        Product_main_Create_On: '',
        Product_main_Create_By: '',
        Product_main_Update_On: '',
        Product_main_Update_By: '',
        Product_type_ID: '',
        Product_type_Code: '',
        product_type_Name: '',
        Product_type_Create_On: '',
        Product_type_Create_By: '',
        Product_type_Update_On: '',
        Product_type_Update_By: '',
        Product_sku_Price: null,
        Product_sku_Package_Unit: '',
        Product_sku_Arrival_Day: null,
        Product_sku_Weight: null,
        Product_sku_Weight_Unit: null,
        Product_sku_Status: '',
        Product_sku_WareHouse: '',
        Product_sku_Barcode: '',
        Product_sku_Description: '',
        Product_sku_Image: null,
        Product_sku_Ingredients: '',
        Product_sku_k_cal: '',
        Product_sku_sodium_mg: '',
        Product_sku_k_joule: '',
        Product_sku_total_fat_g: '',
        Product_sku_sugar_g: '',
        Product_sku_sat_fat_g: '',
        Product_protein_g: '',
        Product_sku_tran_fat_g: '',
        Product_sku_carbohydrate_g: '',
        Product_sku_Create_On: '',
        Product_sku_Create_By: '',
        Product_sku_Update_On: '',
        Product_sku_Update_By: '',
        Product_sku_ref_Create_On: '',
        Product_sku_ref_Create_By: '',
        Product_sku_ref_Update_On: '',
        Product_sku_ref_Update_By: '',
        Product_sku_ref_Value: null
    });

    const [isBound, setBound] = useState<IC0211ProductSKURef[]>([{
        product_sku_id: null,
        product_sku_code: '',
        ref_value: '',
    }]);


    const [isSKUCode, setSKUCode] = useState<IC0211SKUCode[]>([]);

    const PackageUnit = [
        { value: "包", displayName: "包" },
        { value: "件", displayName: "件" },
        { value: "件", displayName: "件" },
        { value: "盒", displayName: "盒" },
        { value: "個", displayName: "個" },
        { value: "克", displayName: "克" },
        { value: "盤", displayName: "盤" },
        { value: "條", displayName: "條" },
    ]

    const WeightUnit = [
        { value: "g", displayName: "g" },
        { value: "kg", displayName: "kg" },
        { value: "oz", displayName: "oz" },
        { value: "pcs", displayName: "pcs" },
        { value: "ml", displayName: "ml" },
        { value: "L", displayName: "L" },
    ]

    const Status = [
        { value: "Y", displayName: "開啟" },
        { value: "N", displayName: "關閉" },
        { value: "L", displayName: "缺貨" },
    ]

    const WareHouse = [
        { value: "6F", displayName: "6樓" },
        { value: "10F", displayName: "10樓" },
        { value: "4F", displayName: "4樓" },
    ]

    const SKURef = [
        { value: "is_shop_order", displayName: "Shop Order" },
        { value: "is_tmall", displayName: "Tmall" },
        { value: "is_ztore", displayName: "Ztore" },
        { value: "is_ecom", displayName: "Ecom" },
        { value: "is_hktvmall", displayName: "HKTVmall" },
        { value: "is_pandamart", displayName: "Pandamart" },
    ]

    useEffect(() => {
        setLoading(true)
        getProductSKUData()
    }, [])

    useEffect(() => {
        if (!isShowAlert) return;
        setTimeout(() => {
            setShowAlert(false);
            setAlertMessage('');
        }, 8000);
    }, [isShowAlert])

    const returnToProductList = () => {
        setProductID && setProductID(null)
        setProductSKUID && setProductSKUID(null)
        setEditMode && setEditMode(null)
    }

    const getProductSKUData = async () => {
        const token = cookies.get('token')

        if (isProductID == null) return;
        if (isProductSKUID == null) return;

        try {

            if (isProductSKUCode == null) return;
            console.log(isProductSKUCode.substring(0, 5))
            const resSKUCode = await axios.post(`${process.env.REACT_APP_API_URL}/c0502_vba/product/GetProductSKUCode`, {
                product_code: isProductSKUCode.substring(0, 5)
            }, {
                headers: { Authorization: `Bearer ${token}` }
            });

            const resSKU = await axios.post(`${process.env.REACT_APP_API_URL}/c0502_vba/product/goUpdateProductList`, {
                product_main_id: isProductID,
                product_sku_id: isProductSKUID
            }, {
                headers: { Authorization: `Bearer ${token}` }
            });

            const AllProductTypeRes = await axios.get(`${process.env.REACT_APP_API_URL}/c0502_vba/product/InsertProductType`, {
                headers: { Authorization: `Bearer ${token}` }
            });

            if (resSKUCode.status === 200) {
                setSKUCode(resSKUCode.data['ProductSKUCode']);
            }

            if (resSKUCode.status === 200 && resSKUCode.data['ProductSKURef']) {
                setBound(resSKUCode.data['ProductSKURef']);
            }

            if (resSKU.status === 200) {
                setData(resSKU.data.Product)
            }

            if (AllProductTypeRes.status === 200) {
                setTypeData(AllProductTypeRes.data['Product Type']);
            }

        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    // Handle changes in the input fields
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleInOutBoundChange = (e: React.ChangeEvent<HTMLSelectElement>, type: string) => {
        const { value } = e.target;

        setBound((prevBound) => {
            // Create a copy of the state to manipulate
            const updatedBound = [...prevBound];

            // Find the index of the item we want to update based on the ref_value (inbound/outbound)
            const index = updatedBound.findIndex((item) => item.ref_value === type);

            if (index !== -1) {
                // If it exists, update its SKU code
                updatedBound[index].product_sku_code = value;
            } else {
                // Otherwise, add a new entry for inbound or outbound
                updatedBound.push({
                    product_sku_id: Number(value),
                    product_sku_code: '',
                    ref_value: type,
                });
            }

            return updatedBound;
        });
    };

    // Handle checkbox changes
    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = e.target;
        setData(prevData => {
            let updatedRefValues = [...(prevData.Product_sku_ref_Value || [])];
            if (checked) {
                // Add item if it is checked
                if (!updatedRefValues.includes(name)) {
                    updatedRefValues.push(name);
                }
            } else {
                // Remove item if it is unchecked
                updatedRefValues = updatedRefValues.filter(value => value !== name);
            }
            return {
                ...prevData,
                Product_sku_ref_Value: updatedRefValues
            };
        });
    };

    // Generalized function to handle file change for both inputs
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, inputId: string) => {
        const selectedFile = event.target.files?.[0];
        if (selectedFile) {
            setFileData((prevData) => ({
                ...prevData,
                [inputId]: {
                    file: selectedFile,
                    previewUrl: URL.createObjectURL(selectedFile),
                },
            }));
        }
    };

    const handleFileRemove = (inputId: string) => {
        setFileData((prevData) => ({
            ...prevData,
            [inputId]: {
                file: null,
                previewUrl: null,
            },
        }));
    };

    const saveDate = async () => {
        let uploadedImageUrl1 = data.Product_main_Image;
        let uploadedImageUrl2 = data.Product_sku_Image;

        setLoading(true)
        if (data.Product_SKU_ID == null) {
            setAlertType('Error');
            setAlertMessage('Product SKU ID is null!');
            setShowAlert(true)
            setLoading(false)
            return;
        };

        if (data.Product_Main_ID == null) {
            setAlertType('Error');
            setAlertMessage('Product Main ID is null!');
            setShowAlert(true)
            setLoading(false)
            return;
        }

        if (data.Product_sku_Weight == null) {
            setAlertType('Error');
            setAlertMessage('Product Weight is null!');
            setShowAlert(true)
            setLoading(false)
            return;
        }

        if (data.Product_sku_Weight_Unit == null) {
            setAlertType('Error');
            setAlertMessage('Product Weight Unit is null!');
            setShowAlert(true)
            setLoading(false)
            return;
        }

        if (data.Product_sku_Arrival_Day == null) {
            setAlertType('Error');
            setAlertMessage('Product Arrival Day is null!');
            setShowAlert(true)
            setLoading(false)
            return;
        }

        if (data.Product_sku_Status == null) {
            setAlertType('Error');
            setAlertMessage('Product Status is null!');
            setShowAlert(true)
            setLoading(false)
            return;
        }

        // console.log(isBound.find(bound => bound.ref_value === "inbound")?.product_sku_id)
        // console.log(isBound.find(bound => bound.ref_value === "outbound")?.product_sku_id)
        if (fileData.fileInput1.file !== null) {
            uploadedImageUrl1 = (await uploadImageToAzureUsingREST('Product_main_Image')) || data.Product_main_Image;
            if (!uploadedImageUrl1) {
                setShowAlert(true)
                setAlertType('Error')
                setAlertMessage('Please enter product description.')
                setLoading(false)
                return;
            }
        }

        if (fileData.fileInput2.file !== null) {
            await uploadImageToAzureUsingREST('');
            uploadedImageUrl2 = (await uploadImageToAzureUsingREST('Product_sku_Image')) || data.Product_sku_Image;
            if (!uploadedImageUrl2) {
                setShowAlert(true)
                setAlertType('Error')
                setAlertMessage('Please enter product description.')
                setLoading(false)
                return;
            }
        }

        try {
            console.log(data)
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/c0502_vba/product/UpdateProductDetail`, {
                product_main_id: data.Product_Main_ID,
                product_main_name: data.Product_Name,
                product_main_description: data.Product_main_Description,
                product_main_status: data.Product_main_Status,
                product_main_image: uploadedImageUrl1,
                product_type_id: data.Product_type_ID,
                product_sku_id: data.Product_SKU_ID,
                product_sku_price: data.Product_sku_Price,
                product_sku_package_unit: data.Product_sku_Package_Unit,
                product_sku_arrival_day: data.Product_sku_Arrival_Day,
                product_sku_weight: data.Product_sku_Weight,
                product_sku_weight_unit: data.Product_sku_Weight_Unit,
                product_sku_status: data.Product_sku_Status,
                product_sku_warehouse: data.Product_sku_WareHouse,
                product_sku_barcode: data.Product_sku_Barcode,
                product_sku_description: data.Product_sku_Description,
                product_sku_image: uploadedImageUrl2,
                product_sku_ingredients: data.Product_sku_Ingredients,
                product_sku_k_cal: data.Product_sku_k_cal,
                product_sku_sodium_mg: data.Product_sku_sodium_mg,
                product_sku_k_joule: data.Product_sku_k_joule,
                product_sku_total_fat_g: data.Product_sku_total_fat_g,
                product_sku_sugar_g: data.Product_sku_sugar_g,
                product_sku_sat_fat_g: data.Product_sku_sat_fat_g,
                product_sku_protein_g: data.Product_protein_g,
                product_sku_tran_fat_g: data.Product_sku_tran_fat_g,
                product_sku_carbohydrate_g: data.Product_sku_carbohydrate_g,
                product_sku_ref_value: data.Product_sku_ref_Value
            }, {
                headers: { Authorization: `Bearer ${cookies.get('token')}` }
            });


            if (res.status === 200 || res.status === 201) {
                setShowAlert(true)
                setAlertType('Success');
                setAlertMessage('Product data update successfully!');
            }
        } catch (error) {
            console.log(error)
            setAlertType('Error');
            setAlertMessage('Product data update failed!');
            setShowAlert(true)
        } finally {
            setLoading(false)
        }

    }

    const uploadImageToAzureUsingREST = async (imgType: string) => {

        let selectedFile

        if (imgType === 'Product_main_Image') {
            if (fileData.fileInput1.file === null) {
                console.error("No file selected for upload.");
                return;
            } else {
                selectedFile = fileData.fileInput1.file;
            }
        } else if (imgType === 'Product_sku_Image') {
            if (fileData.fileInput2.file === null) {
                console.error("No file selected for upload.");
                return;
            } else {
                selectedFile = fileData.fileInput2.file;
            }
        }

        if (selectedFile === null || selectedFile === undefined) {
            console.error("No file selected for upload.");
            return;
        }

        // Ensure the file size is less than the maximum allowed
        if (selectedFile.size > MAX_FILE_SIZE) {
            setShowAlert(true);
            setAlertType("Error");
            setAlertMessage("圖片檔案過大(5MB或以下)");
            return;
        }

        // Create a unique blob name
        const today = new Date();
        const day = String(today.getDate()).padStart(2, '0');
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const year = String(today.getFullYear()).slice(-2);
        const minutes = String(today.getMinutes()).padStart(2, '0');
        const fileType = selectedFile.type.split('/')[1]; // Extract file extension
        let blobName
        
        if (imgType === 'Product_main_Image') {
        blobName = `C0211${year}${month}${day}${isProductSKUCode?.substring(0,5)}${minutes}.${fileType}`;
        } else if (imgType === 'Product_sku_Image') {
        blobName = `C0211${year}${month}${day}${isProductSKUCode}${minutes}.${fileType}`;
        }

        // Construct the full Azure URL for uploading
        const containerName = "c0211";
        const azureUrl = `https://wisetechstorage.blob.core.windows.net/${containerName}/${blobName}`;

        // Include a valid SAS token for upload (make sure to generate a valid token)
        const sasToken = "?sv=2020-02-10&ss=bfqt&srt=sco&sp=rwdlacuptfx&se=2031-06-04T10:48:46Z&st=2021-06-04T02:48:46Z&spr=https&sig=%2BkOldhVIFZRvDjh9iNADaik4s7SJwrw0k31iYg51d7Q%3D"; // Replace this with a valid SAS token
        const azureUploadUrl = `${azureUrl}?${sasToken}`;

        try {
            // Upload to Azure Blob Storage using PUT
            const response = await fetch(azureUploadUrl, {
                method: 'PUT',
                headers: {
                    'Content-Type': selectedFile.type,
                    'x-ms-blob-type': 'BlockBlob',
                },
                body: selectedFile,
            });

            if (response.ok) {
                console.log("Upload successful:", azureUploadUrl);
                return azureUploadUrl
                // setAlertType("Success");
                // setAlertMessage("圖片上傳成功");
                // setShowAlert(true);
            } else {
                const errorText = await response.text();
                console.error("Upload failed:", response.statusText, errorText);
                setAlertType("Error");
                setAlertMessage("圖片上傳失敗，請再試一次");
                setShowAlert(true);
            }
        } catch (error) {
            console.error("Error:", error);
            setAlertType("Error");
            setAlertMessage("上傳過程中發生錯誤");
            setShowAlert(true);
        }
    };

    const content = (
        <>
            {isShowAlert && <AlertMessage ismessageType={isAlertType} isAlertMessage={isAlertMessage} />}
            {isloading && <DeafultLoadingBar />}

            <div className={css.buttonContainer}>
                <button className={css.ReturnListBtn} onClick={() => returnToProductList()}>
                    <i className={`fi fi-br-angle-double-left ${css.returntoListIcon}`} />
                    Back To Product List
                </button>
            </div>

            <section className={css.MainSection}>

                <div className={css.SubTitleDiv}>
                    <h1>Base Information</h1>
                    <div className={css.DisplayInformation}>
                        <i className="fi fi-sr-info"
                            onMouseEnter={() => setShowUploadInfo(true)}
                            onMouseLeave={() => setShowUploadInfo(false)}
                        />
                        {isshowuploadinfo && (


                            <div className={css.HideuploadInfoDiv}>
                                <div className={css.uploadInfoItem}>
                                    <label>Create Date</label>
                                    <input
                                        type="text"
                                        value={data?.Product_sku_Create_On}
                                    />
                                </div>

                                <div className={css.uploadInfoItem}>
                                    <label>Create By</label>
                                    <input
                                        type="text"
                                        value={data?.Product_sku_Create_By}
                                    />
                                </div>

                                <div className={css.uploadInfoItem}>
                                    <label>Update Data</label>
                                    <input
                                        type="text"
                                        value={data?.Product_sku_Update_On}
                                    />
                                </div>

                                <div className={css.uploadInfoItem}>
                                    <label>Update By</label>
                                    <input
                                        type="text"
                                        value={data?.Product_sku_Update_By}
                                    />
                                </div>

                            </div>

                        )}
                    </div>
                </div>

                <div className={css.baseInfoDiv}>

                    <div className={css.userImageDiv}>
                        {fileData.fileInput1.previewUrl && fileData.fileInput1.previewUrl.length >= 0 ? (
                            <>
                                <img src={fileData.fileInput1.previewUrl} alt="Product_main_Image" />
                                <button className={css.PhotoBtn} onClick={() => handleFileRemove('fileInput1')}>
                                    <i className={`fi fi-rr-file-upload ${css.EditPhotoIcon}`} />
                                    Remove
                                </button>
                            </>
                        ) : (
                            <>
                                {data?.Product_main_Image ? (
                                    <img src={data.Product_main_Image} alt="Product_main_Image" />
                                ) : (
                                    <img src={DefaultProductImg} alt="Product_main_Image" />
                                )}
                                <button className={css.PhotoBtn} onClick={() => console.log("This function not ready.")}>
                                    <input type="file" accept="image/*" onChange={(e) => handleFileChange(e, 'fileInput1')} />
                                    <i className={`fi fi-rr-file-upload ${css.EditPhotoIcon}`} />
                                    Edit
                                </button>
                            </>
                        )}
                    </div>

                    <div className={css.baseInfo1}>
                        <div className={css.baseInfoItem}>
                            <label>Product SKU Code </label>
                            <input type="text" value={isProductSKUCode ?? ''} disabled={true} />
                        </div>

                        <div className={css.baseInfoItem}>
                            <label>Status</label>
                            <select name="Product_sku_Status" value={data?.Product_sku_Status} onChange={handleInputChange}>
                                <option value="Y">開啟</option>
                                <option value="N">關閉</option>
                            </select>
                        </div>

                        <div className={css.baseInfoItem}>
                            <label>Product Name </label>
                            <input type="text" name="Product_Name" value={data?.Product_Name} onChange={handleInputChange} />
                        </div>

                        <div className={css.baseInfoItem}>
                            <label>Product Type</label>
                            <select name="Product_type_ID" value={data?.Product_type_ID} onChange={handleInputChange}>
                                {typedata.map((item, index) => (
                                    <option key={index} value={item.product_type_id}>{item.product_type_code} | {item.product_type_name}</option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className={css.baseInfo2}>
                        <div className={css.baseInfoItem}>
                            <label>Remark</label>
                            <input type="text" name="Product_main_Description" value={data?.Product_main_Description} onChange={handleInputChange} />
                        </div>

                        <div className={css.baseInfoItem}>
                            <label>Inbound Package</label>
                            <select name="inbound" value={isBound.find(bound => bound.ref_value === "inbound")?.product_sku_id || ""} onChange={(e) => handleInOutBoundChange(e, "inbound")}>
                                <option value=""></option>
                                {isSKUCode.map((item, index) => (
                                    <option key={index} value={item.product_sku_id}>{item.product_sku_code}</option>
                                ))}
                            </select>
                        </div>

                        <div className={css.baseInfoItem}>
                            <label>Outbound Package</label>
                            <select name="outbound" value={isBound.find(bound => bound.ref_value === "outbound")?.product_sku_id || ""} onChange={(e) => handleInOutBoundChange(e, "outbound")}>
                                <option value=""></option>
                                {isSKUCode.map((item, index) => (
                                    <option key={index} value={item.product_sku_id}>{item.product_sku_code}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>

                <div className={css.SelectTypeInfoDiv}>
                    <div className={css.SelectTypeBtnContainer}>
                        <button className={css.SelectTypeBtn} onClick={() => setMode(1)}>
                            <i className={`fi fi-rr-document ${css.SelectTypeIcon}`} />
                            SKU Information
                        </button>
                    </div>

                    <div className={css.SelectTypeBtnContainer}>
                        <button className={css.SelectTypeBtn} onClick={() => setMode(2)}>
                            <i className={`fi fi-rs-brand ${css.SelectTypeIcon}`} />
                            Food Labels
                        </button>
                    </div>

                    <div className={css.SelectTypeBtnContainer}>
                        <button className={css.SelectTypeBtn} onClick={() => setMode(3)}>
                            <i className={`fi fi-rs-seller-store ${css.SelectTypeIcon}`} />
                            Store Order
                        </button>
                    </div>
                </div>

            </section>

            {ismode === 1 &&
                <section className={css.SubSection}>
                    <div className={css.SubInfoTitleDiv}>
                        <h1>SKU Information</h1>
                    </div>

                    <div className={css.personalInfoDiv}>


                        <div className={css.baseInfoDiv}>

                            <div className={css.userImageDiv}>
                                {fileData.fileInput2.previewUrl && fileData.fileInput2.previewUrl.length >= 0 ? (
                                    <>
                                        <img src={fileData.fileInput2.previewUrl} alt="Product_main_Image" />
                                        <button className={css.PhotoBtn} onClick={() => handleFileRemove('fileInput2')}>
                                            <i className={`fi fi-rr-file-upload ${css.EditPhotoIcon}`} />
                                            Remove
                                        </button>
                                    </>
                                ) : (
                                    <>
                                        {data?.Product_main_Image ? (
                                            <img src={data.Product_main_Image} alt="Product_main_Image" />
                                        ) : (
                                            <img src={DefaultProductImg} alt="Product_main_Image" />
                                        )}
                                        <button className={css.PhotoBtn} onClick={() => console.log("This function not ready.")}>
                                            <input type="file" accept="image/*" onChange={(e) => handleFileChange(e, 'fileInput2')} />
                                            <i className={`fi fi-rr-file-upload ${css.EditPhotoIcon}`} />
                                            Edit
                                        </button>
                                    </>
                                )}
                            </div>

                            <div className={css.baseInfo1}>
                                <div className={css.baseInfoItem}>
                                    <label>Price </label>
                                    <input type="number" name="Product_sku_Price" value={data?.Product_sku_Price ?? ''} onChange={handleInputChange} />
                                </div>

                                <div className={css.baseInfoItem}>
                                    <label>Package Unit</label>
                                    <select name="Product_sku_Package_Unit" value={data?.Product_sku_Package_Unit} onChange={handleInputChange}>
                                        {PackageUnit.map((item, index) => (
                                            <option key={index} value={item.value}>{item.displayName}</option>
                                        ))}
                                    </select>
                                </div>

                                <div className={css.baseInfoItem}>
                                    <label>Arrival Day</label>
                                    <input type="number" name="Product_sku_Arrival_Day" value={data?.Product_sku_Arrival_Day ?? ''} onChange={handleInputChange} />
                                </div>

                                <div className={css.baseInfoItem}>
                                    <label>Product Weight</label>
                                    <div className={css.WeightDiv}>
                                        <input type="number" name="Product_sku_Weight" value={data?.Product_sku_Weight ?? ''} onChange={handleInputChange} />
                                        <select name="Product_sku_Weight_Unit" value={data?.Product_sku_Weight_Unit ?? ''} onChange={handleInputChange}>
                                            {WeightUnit.map((item, index) => (
                                                <option key={index} value={item.value}>{item.displayName}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className={css.baseInfo2}>

                                <div className={css.baseInfoItem}>
                                    <label>Status</label>
                                    <select name="Product_sku_Status" value={data?.Product_sku_Status} onChange={handleInputChange}>
                                        {Status.map((item, index) => (
                                            <option key={index} value={item.value}>{item.displayName}</option>
                                        ))}
                                    </select>
                                </div>

                                <div className={css.baseInfoItem}>
                                    <label>WareHouse</label>
                                    <select name="Product_sku_Status" value={data?.Product_sku_WareHouse} onChange={handleInputChange}>
                                        {WareHouse.map((item, index) => (
                                            <option key={index} value={item.value}>{item.displayName}</option>
                                        ))}
                                    </select>
                                </div>

                                <div className={css.baseInfoItem}>
                                    <label>Description</label>
                                    <input type="text" name="Product_sku_Description" value={data?.Product_sku_Description} onChange={handleInputChange} />
                                </div>

                                <div className={css.baseInfoItem}>
                                    <label>Barcode</label>
                                    <input type="text" name="Product_sku_Barcode" value={data?.Product_sku_Barcode} onChange={handleInputChange} />
                                </div>

                            </div>
                        </div>
                    </div>


                </section>
            }

            {ismode === 2 &&
                <section className={css.SubSection}>
                    <div className={css.SubInfoTitleDiv}>
                        <h1>Food Labels</h1>
                    </div>

                    <div className={css.personalInfoDiv}>
                        <div className={css.personalInfoItem}>
                            <div className={css.baseInfoItem}>
                                <label>Ingredients</label>
                                <textarea value={data?.Product_sku_Ingredients} cols={20} rows={10} name="Product_sku_Ingredients" onChange={handleInputChange} />
                            </div>
                        </div>

                        <div className={css.personalInfoItem}>
                            <div className={css.baseInfoItem}>
                                <label>k cal</label>
                                <input type="text" name="Product_sku_k_cal" value={data?.Product_sku_k_cal} onChange={handleInputChange} />
                            </div>

                            <div className={css.baseInfoItem}>
                                <label>k joule</label>
                                <input type="text" name="Product_sku_k_joule" value={data?.Product_sku_k_joule} onChange={handleInputChange} />
                            </div>

                            <div className={css.baseInfoItem}>
                                <label>sugar g</label>
                                <input type="text" name="Product_sku_sugar_g" value={data?.Product_sku_sugar_g} onChange={handleInputChange} />
                            </div>

                            <div className={css.baseInfoItem}>
                                <label>protein g</label>
                                <input type="text" name="Product_protein_g" value={data?.Product_protein_g} onChange={handleInputChange} />
                            </div>

                            <div className={css.baseInfoItem}>
                                <label>carbohydrate g</label>
                                <input type="text" name="Product_sku_carbohydrate_g" value={data?.Product_sku_carbohydrate_g} onChange={handleInputChange} />
                            </div>
                        </div>

                        <div className={css.personalInfoItem}>
                            <div className={css.baseInfoItem}>
                                <label>sodium mg</label>
                                <input type="text" name="Product_sku_sodium_mg" value={data?.Product_sku_sodium_mg} onChange={handleInputChange} />
                            </div>

                            <div className={css.baseInfoItem}>
                                <label>total fat g</label>
                                <input type="text" name="Product_sku_total_fat_g" value={data?.Product_sku_total_fat_g} onChange={handleInputChange} />
                            </div>

                            <div className={css.baseInfoItem}>
                                <label>sat fat g</label>
                                <input type="text" name="Product_sku_sat_fat_g" value={data?.Product_sku_sat_fat_g} onChange={handleInputChange} />
                            </div>

                            <div className={css.baseInfoItem}>
                                <label>tran fat g</label>
                                <input type="text" name="Product_sku_tran_fat_g" value={data?.Product_sku_tran_fat_g} onChange={handleInputChange} />
                            </div>

                        </div>
                    </div>

                </section>
            }

            {ismode === 3 &&
                <section className={css.SubSection}>
                    <div className={css.SubInfoTitleDiv}>
                        <h1>Store Order</h1>
                    </div>

                    <div className={css.personalInfoDiv}>
                        <div className={css.personalInfoItem}>
                            <div className={css.baseInfoItem}>
                                <fieldset className={css.TypeFieldset}>
                                    <legend>SKU Ref</legend>
                                    {SKURef.map((RefType) => (
                                        <div key={RefType.value} className={css.ProductTypeFieldset}>
                                            <input
                                                type="checkbox"
                                                id={RefType.value}
                                                name={RefType.value}
                                                checked={data?.Product_sku_ref_Value?.includes(RefType.value) || false}
                                                onChange={handleCheckboxChange}
                                            />
                                            <label htmlFor={RefType.value} className={css.Typelable}>
                                                {RefType.displayName}
                                            </label>
                                        </div>
                                    ))}
                                </fieldset>
                            </div>
                        </div>

                    </div>

                </section>
            }

            {/* Bottom  Control Buttons */}
            <section className={css.BottomSection}>
                <div className={css.SaveBtnContainer}>
                    <button className={css.BottomBtn} onClick={() => saveDate()}>
                        <i className="fi fi-rs-disk" />
                        Save
                    </button>
                </div>
            </section>

        </>
    )
    return content
}

export default C0211EditProductSKUDetail