import { useNavigate } from 'react-router-dom';
import { useState, ChangeEvent, useEffect, useContext } from 'react'
import { Cookies } from 'react-cookie';
import Lcss from '../css/Login.module.css'
import axios from 'axios';
import { IToken } from '../Interface/interface';
import { UserContext } from '../Class/UserPara';
import CryptoJS from 'crypto-js';  // Importing crypto-js
import AlertMessage from './LoadingBar/AlertMessage';



const cookies = new Cookies();


const Login = () => {

    const { userData, setUserData } = useContext(UserContext);

    const navigate = useNavigate();
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [rememberMe, setRememberMe] = useState(false)
    const [loading, IsLoagin] = useState(false)
    const [visible, setVisible] = useState(false)
    const [codestatus, setCodeStatus] = useState(true)
    const [countlogin, setCountLogin] = useState(0)
    const [loginerrormsg, SetLoginErrorMsg] = useState('您的用戶名或密碼不正確。 請重試！')
    const [ACID, setACID] = useState("c0000");
    const [tempsave, setTempSave] = useState<number>(0);

    const [isverificodeMode, setVerifiCodeMode] = useState<boolean>(false);
    const [isverificode, setVerifiCode] = useState<string>('');

    const [isShowAlert, setShowAlert] = useState<boolean>(false);
    const [isAlertType, setAlertType] = useState<string>('Info');
    const [isAlertMessage, setAlertMessage] = useState<string>('');

    useEffect(() => {
        console.log("Login Version: 1.0.4")
    }, [])


    useEffect(() => {
        if (!isShowAlert) return;
        setTimeout(() => {
            setShowAlert(false);
            setAlertMessage('');
        }, 8000);
    }, [isShowAlert])


    const handleACIDInput = (e: ChangeEvent<HTMLSelectElement>) => {
        setACID(e.target.value);
    };
    const handleUserInput = (e: ChangeEvent<HTMLInputElement>) => setUsername(e.target.value);
    const handlePasswordInput = (e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value);
    const handleRememberMeInput = (e: ChangeEvent<HTMLInputElement>) => setRememberMe(e.target.checked)
    const handleVerifiCodeInput = (e: ChangeEvent<HTMLInputElement>) => setVerifiCode(e.target.value);

    const togglepassword = () => {
        if (visible === true) {
            setVisible(false)
        } else {
            setVisible(true)
        }
    }

    const LoginRouter = () => {
        switch (ACID) {
            case "c0000":
                LoginBtn();
                break;
            case "c0211":
                c0502LoginFnc();
                break;
            case "c0217":
                c0217LoginFnc();
                break;
        }
    }

    const LoginBtn = () => {
        IsLoagin(true)
        axios.post(`${process.env.REACT_APP_API_URL}/user/RequestLoginToken`, {
            email_id: username,
            password: password
        })
            .then(function (response) {
                const token: IToken = response.data
                cookies.set('token', token.token)
                cookies.set('ACID', ACID)
                getUserData(token.token)
                setCodeStatus(true)
                setCountLogin(0)
            })
            .catch(function (error) {
                // console.log(error);
                setCodeStatus(false)
                setCountLogin(countlogin + 1)
                SetLoginErrorMsg('您的用戶名或密碼不正確。 請重試！')

                if (countlogin >= 20) {
                    const element = document.getElementById("LoginBtn")
                    element?.classList.add(`${Lcss.LoginBtn2}`)
                    element?.classList.remove(`${Lcss.LoginBtn}`)
                    cookies.set('LoginCoolDown', false)
                    SetLoginErrorMsg('抱歉!您的登入嘗試失敗。我們需要強制執行5分鐘的冷卻時間')
                }
            });
    }

    const c0502LoginFnc = () => {

        const SHAPassword = CryptoJS.SHA256(username.toUpperCase() + password).toString()
        console.log(SHAPassword)

        axios.post(`${process.env.REACT_APP_API_URL}/c0502_vba/user/RequestLoginToken`, {
            ac_id: ACID,
            login_email: username,
            password: SHAPassword
        })
            .then(function (response) {
                const token: IToken = response.data
                cookies.set('token', token.token)
                cookies.set('ACID', ACID)
                //getUserDataByToken_C0502(token.token)
                setCodeStatus(true)
                setCountLogin(0)
                window.location.replace(`${process.env.REACT_APP_WEBSITE_URL}`);
            })
            .catch(function (error) {
                setCodeStatus(false)
                setCountLogin(countlogin + 1)
                SetLoginErrorMsg('您的用戶名或密碼不正確。 請重試！')

                if (countlogin >= 20) {
                    const element = document.getElementById("LoginBtn")
                    element?.classList.add(`${Lcss.LoginBtn2}`)
                    element?.classList.remove(`${Lcss.loginBtn}`)
                    cookies.set('LoginCoolDown', false)
                    SetLoginErrorMsg('抱歉!您的登入嘗試失敗。我們需要強制執行5分鐘的冷卻時間')
                }
            });
    }

    const c0217LoginFnc = async () => {

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        // Check if the username (email) is empty or invalid
        if (!username) {
            setAlertType('Error');
            setAlertMessage('Please enter your email address.'); // Please enter an email address.
            setShowAlert(true);
            return;
        }

        if (!emailRegex.test(username)) {
            setAlertType('Error');
            setAlertMessage('Please enter a valid email address.'); // Please enter a valid email address.
            setShowAlert(true);
            return;
        }

        try {

            //const res = await axios.post(`http://192.168.68.182:8000/c0217_vba/user/GetMailToken`, {
            //const res = await axios.post(`http://192.168.0.128:8000/c0217_vba/user/GetMailToken`, {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/c0217_vba/user/GetMailToken`, {
                login_email: username
            }, {
                withCredentials: true
            });

            if (res.status === 200) {
                localStorage.setItem("sessionToken", res.data.verification_code);
                cookies.set('sessionToken', res.data.verification_code)
                setTempSave(res.data.verification_code)
                setAlertType('Info');
                setAlertMessage('The verification code has been successfully sent to your email inbox.');
                setVerifiCodeMode(true);
            }

        } catch (error) {
            console.log(error)
            setAlertType('Error');
            setAlertMessage('Fail to send the verification code');
        } finally {
            setShowAlert(true)
        }
    }

    const c0217VerifiFnc = async () => {
        try {

            console.log(isverificode)
            const token = localStorage.getItem("sessionToken"); // Get the token from storage

            const res = await axios.post(`${process.env.REACT_APP_API_URL}/c0217_vba/user/VerifyMailToken`, {
                code: isverificode,
            }, {
                withCredentials: true
            });

            if (res.status === 200) {
                setAlertType('Success');
                setAlertMessage('You have successfully logged in.');
                cookies.set('ACID', ACID)
                c0217GetUserToken()
            }

            // const isverificodeForCK = parseInt(isverificode)


            // if (tempsave === isverificodeForCK) {
            //     c0217GetUserToken()
            // }
            // const res = await axios.post(`http://192.168.68.182:8000/c0217_vba/user/VerifyMailToken`, {
            // const res = await axios.post(`http://192.168.0.128:8000/c0217_vba/user/VerifyMailToken`, {

        } catch (error) {
            setAlertType('Error');
            setAlertMessage('Error in verifying the verification code.');
        } finally {
            setShowAlert(true)
        }
    }

    const c0217GetUserToken = async () => {
        try {

            const tokenres = await axios.post(`${process.env.REACT_APP_API_URL}/c0217_vba/user/RequestLoginTokenByEmail`, {
                login_email: username
            }, {});

            if (tokenres.status === 200) {
                cookies.set('token', tokenres.data.token)
                setAlertType('Success');
                setAlertMessage('You have successfully logged in.');
                cookies.set('ACID', ACID)
                window.location.replace(`${process.env.REACT_APP_WEBSITE_URL}/C0217/`);
            }

        } catch (error) {
            setAlertType('Error');
            setAlertMessage('Error in verifying the verification code.');
        } finally {
            setShowAlert(true)
        }
    }


    // Get User Data by Token
    const getUserDataByToken_C0211 = (token: string) => {
        axios.post(`${process.env.REACT_APP_API_URL}/user/GetUserDataByToken`, {}, {
            headers: { Authorization: `Bearer ${token}` }
        })
            .then(async function (response) {
                let permissionsList = ''; // Initialize as an empty string
                const permissions = response.data.permissions;
                const permissionsSize = permissions.length;

                for (let i = 0; i < permissionsSize; i++) {
                    permissionsList += permissions[i].permission_id;
                    if (i < permissionsSize - 1) {
                        permissionsList += '--';
                    }
                }

                console.log(response.data)

                cookies.set('Uid', response.data.user_id)
                cookies.set('Email', response.data.user_id)
                cookies.set('UserName', response.data.user_id)
                cookies.set('Authority_level', response.data.user_id)
                cookies.set('Login_count', response.data.user_id)
                cookies.set('User_status', response.data.user_id)
                cookies.set('User_status', response.data.permissions)
                cookies.set('PermissionList', permissionsList)
                cookies.set('Login_status', "true")

                setUserData(response.data)

                //window.location.replace(`${process.env.REACT_APP_WEBSITE_URL}`);
            })
            .catch(function (error) {
                cookies.remove('Uid')
                cookies.remove('Email')
                cookies.remove('UserName')
                cookies.remove('Authority_level')
                cookies.remove('Login_count')
                cookies.remove('User_status')
                cookies.remove('User_status')
                cookies.remove('PermissionList')
                cookies.remove('Login_status')
                console.log(error);
            });
    }

    const getUserData = (token: string) => {
        axios.post(`${process.env.REACT_APP_API_URL}/user/GetUserDataByToken`, {}, {
            headers: { Authorization: `Bearer ${token}` }
        })
            .then(async function (response) {
                let permissionsList = ''; // Initialize as an empty string
                const permissions = response.data.permissions;
                const permissionsSize = permissions.length;

                for (let i = 0; i < permissionsSize; i++) {
                    permissionsList += permissions[i].permission_id;
                    if (i < permissionsSize - 1) {
                        permissionsList += '--';
                    }
                }

                console.log(response.data)

                cookies.set('Uid', response.data.user_id)
                cookies.set('Email', response.data.user_id)
                cookies.set('UserName', response.data.user_id)
                cookies.set('Authority_level', response.data.user_id)
                cookies.set('Login_count', response.data.user_id)
                cookies.set('User_status', response.data.user_id)
                cookies.set('User_status', response.data.permissions)
                cookies.set('PermissionList', permissionsList)
                cookies.set('Login_status', "true")

                setUserData(response.data)

                window.location.replace(`${process.env.REACT_APP_WEBSITE_URL}`);
            })
            .catch(function (error) {
                cookies.remove('Uid')
                cookies.remove('Email')
                cookies.remove('UserName')
                cookies.remove('Authority_level')
                cookies.remove('Login_count')
                cookies.remove('User_status')
                cookies.remove('User_status')
                cookies.remove('PermissionList')
                cookies.remove('Login_status')
                console.log(error);
            });
    }

    const content = (
        <>
            {isShowAlert && <AlertMessage ismessageType={isAlertType} isAlertMessage={isAlertMessage} />}
            <section className={Lcss.MainSection}>
                <div className={Lcss.LoginMain}>

                    {!isverificodeMode ? (
                        <div className={Lcss.LoginForm}>

                            <p className={Lcss.Title}>登入</p>

                            <div className={Lcss.Loginlab}>
                                <label htmlFor="ACID">公司:</label>
                                <select
                                    name="ACID"
                                    id="ACID"
                                    className={Lcss.LoginComboBox}
                                    onChange={handleACIDInput}
                                >
                                    <option value="c0000">Wisetech</option>
                                    <option value="c0211">紹香園</option>
                                    <option value="c0217">Taste of Asia</option>
                                </select>
                            </div>

                            {ACID === "c0217" ? (
                                <div className={Lcss.Loginlab}>
                                    <label htmlFor="exampleInputEmail1" >電子郵件 :</label>
                                    <input
                                        className={Lcss.LoginTextBox}
                                        type="text"
                                        id="username"
                                        autoComplete="off"
                                        value={username}
                                        onChange={handleUserInput}
                                        required
                                    />
                                </div>
                            ) : (
                                <>
                                    <div className={Lcss.Loginlab}>
                                        <label htmlFor="exampleInputEmail1" >電子郵件 :</label>
                                        <input
                                            className={Lcss.LoginTextBox}
                                            type="text"
                                            id="username"
                                            autoComplete="off"
                                            value={username}
                                            onChange={handleUserInput}
                                            required
                                        />
                                    </div>

                                    <div className={Lcss.Loginlab}>
                                        <label htmlFor="exampleInputPassword1" >密碼 :</label>
                                        <input
                                            className={Lcss.LoginTextBox}
                                            type={visible ? "text" : "password"}
                                            id="password"
                                            value={password}
                                            onChange={handlePasswordInput}
                                            required
                                        />
                                        <span className={Lcss.password_toggle_icon}>
                                            {visible ? <i className="fi fi-sr-eye" onClick={togglepassword} /> : <i className="fi fi-sr-eye-crossed" onClick={togglepassword} />}
                                        </span>
                                    </div>

                                    <div className={Lcss.checkboxdiv}>
                                        <input
                                            type="checkbox"
                                            className={Lcss.checkbox}
                                            id="persist"
                                            onChange={handleRememberMeInput}
                                        />
                                        <label className="form-check-label" htmlFor="exampleCheck1">保持登入狀態</label><br />
                                    </div>
                                </>
                            )}

                            {codestatus === false ?

                                <div className={Lcss.LoginStatus}>
                                    <p>{loginerrormsg}</p>
                                </div> :
                                null
                            }

                            {ACID === "c0217" ? (
                                <button id="LoginBtn" className={Lcss.LoginBtn} onClick={LoginRouter}>發送電郵驗証</button>
                            ) : (
                                <button id="LoginBtn" className={Lcss.LoginBtn} onClick={LoginRouter}>登入</button>
                            )}

                        </div>
                    ) : (
                        <>
                            <div className={Lcss.LoginForm}>
                                <p className={Lcss.Title}>二步驗証</p>
                                <div className={Lcss.Loginlab}>
                                    <label htmlFor="exampleInputEmail1" >驗証碼 :</label>
                                    <input
                                        className={Lcss.LoginTextBox}
                                        type="text"
                                        id="verificode"
                                        autoComplete="off"
                                        value={isverificode}
                                        onChange={handleVerifiCodeInput}
                                        required
                                    />
                                </div>

                                <div className={Lcss.OtherOptionDiv}>
                                    <div className={Lcss.OtherOption}>
                                        <button id="LoginBtn" className={Lcss.otheroption} onClick={c0217VerifiFnc}>{`<<重新輸入電郵`}</button>
                                    </div>
                                    <div className={Lcss.OtherOption}>
                                        <button id="LoginBtn" className={Lcss.otheroption} onClick={LoginRouter}>{`重新發送驗証碼 >>`}</button>
                                    </div>
                                </div>
                                <button id="LoginBtn" className={Lcss.LoginBtn} onClick={c0217VerifiFnc}>登入</button>
                            </div>
                        </>
                    )}

                </div>
            </section>
        </>

    )
    return content
}

export default Login

function sha256(arg0: string) {
    throw new Error('Function not implemented.');
}
