import { useEffect, useState } from 'react';
import css from '../../../css/C0211/EditProduct.module.css'
import AlertMessage from '../../LoadingBar/AlertMessage';
import DeafultLoadingBar from '../../LoadingBar/DefaultLoadingBar';
import DefaultProductImg from '../../../global/C0211/LogoBW.png'
import { c0211ProductProps, IC0211Product, IC0211ProductTypeDetail } from '../../../Interface/interface';
import axios from 'axios';
import { Cookies } from 'react-cookie';
const cookies = new Cookies();
const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB

const C0211EditProductTypeDetail: React.FC<c0211ProductProps> = ({ setProductTypeID, isProductTypeID, setEditMode }) => {
    const [isloading, setLoading] = useState<boolean>(false);
    const [isShowAlert, setShowAlert] = useState<boolean>(false);
    const [isAlertType, setAlertType] = useState<string>('Info');
    const [isAlertMessage, setAlertMessage] = useState<string>('');

    const [fileData, setFileData] = useState<{ [key: string]: { file: File | null; previewUrl: string | null } }>({
        fileInput1: { file: null, previewUrl: null },
        fileInput2: { file: null, previewUrl: null },
    });

    const [data, setData] = useState<IC0211ProductTypeDetail>({
        product_type_id: 0,
        ac_id: '',
        product_type_code: '',
        product_type_name: '',
        product_type_desc: '',
        product_type_img: null,
        product_type_status: '',
        create_on: '',
        create_by: '',
        update_on: '',
        update_by: '',
    });

    useEffect(() => {
        if (isProductTypeID !== null) {
            setLoading(true);
            getProductTypeDetail();
        }
    }, [])

    useEffect(() => {
        if (!isShowAlert) return;
        setTimeout(() => {
            setShowAlert(false);
            setAlertMessage('');
        }, 8000);
    }, [isShowAlert])

    const getProductTypeDetail = async () => {
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/c0502_vba/product/GetProductTypeDetail`, {
                product_type_id: isProductTypeID
            }, {
                headers: { Authorization: `Bearer ${cookies.get('token')}` }
            })

            if (res.status === 200) {
                setData(res.data.ProductTypeDetail)
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, inputId: string) => {
        const selectedFile = event.target.files?.[0];
        if (selectedFile) {
            setFileData((prevData) => ({
                ...prevData,
                [inputId]: {
                    file: selectedFile,
                    previewUrl: URL.createObjectURL(selectedFile),
                },
            }));
        }
    };

    const handleFileRemove = (inputId: string) => {
        setFileData((prevData) => ({
            ...prevData,
            [inputId]: {
                file: null,
                previewUrl: null,
            },
        }));
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const SaveProductTypeData = async () => {
        let uploadedImageUrl = data.product_type_img;
        
        if (data.product_type_desc === '') {
            setAlertType('Error');
            setAlertMessage('Product Description is required.');
            setShowAlert(true);
            return;
        }
        setLoading(true);

        if (fileData.fileInput1.file !== null) {
            uploadedImageUrl = (await uploadImageToAzureUsingREST()) || data.product_type_img;
            if (!uploadedImageUrl) {
                setShowAlert(true)
                setAlertType('Error')
                setAlertMessage('Please enter product description.')
                setLoading(false)
                return;
            }
        }

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/c0502_vba/product/InsertUpdateProductType`, {
                product_type_id: isProductTypeID,
                product_type_name: data.product_type_name,
                product_type_desc: data.product_type_desc,
                product_type_img: uploadedImageUrl,
                product_status: data.product_type_status,
            }, {
                headers: { Authorization: `Bearer ${cookies.get('token')}` }
            })

            if (res.status === 200) {
                setAlertType('Success');
                setAlertMessage('Product Type has been saved successfully.');
                setShowAlert(true);
            }
        } catch (error) {
            console.log(error);
            setAlertType('Error');
            setAlertMessage('An error occurred while saving the product type.');
            setShowAlert(true);
        } finally {
            setLoading(false);
        }
    }

    const uploadImageToAzureUsingREST = async () => {
        if (fileData.fileInput1.file === null) {
            console.error("No file selected for upload.");
            return;
        }
    
        const selectedFile = fileData.fileInput1.file;
    
        // Ensure the file size is less than the maximum allowed
        if (selectedFile.size > MAX_FILE_SIZE) {
            setShowAlert(true);
            setAlertType("Error");
            setAlertMessage("圖片檔案過大(5MB或以下)");
            return;
        }
    
        // Create a unique blob name
        const today = new Date();
        const day = String(today.getDate()).padStart(2, '0');
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const year = String(today.getFullYear()).slice(-2);
        const minutes = String(today.getMinutes()).padStart(2, '0');
        const fileType = selectedFile.type.split('/')[1]; // Extract file extension
        const blobName = `C0211${year}${month}${day}${data.product_type_code}${minutes}.${fileType}`;
        
        // Construct the full Azure URL for uploading
        const containerName = "c0211";
        const azureUrl = `https://wisetechstorage.blob.core.windows.net/${containerName}/${blobName}`;
        
        // Include a valid SAS token for upload (make sure to generate a valid token)
        const sasToken = "?sv=2020-02-10&ss=bfqt&srt=sco&sp=rwdlacuptfx&se=2031-06-04T10:48:46Z&st=2021-06-04T02:48:46Z&spr=https&sig=%2BkOldhVIFZRvDjh9iNADaik4s7SJwrw0k31iYg51d7Q%3D"; // Replace this with a valid SAS token
        const azureUploadUrl = `${azureUrl}?${sasToken}`;
    
        try {
            // Upload to Azure Blob Storage using PUT
            const response = await fetch(azureUploadUrl, {
                method: 'PUT',
                headers: {
                    'Content-Type': selectedFile.type,
                    'x-ms-blob-type': 'BlockBlob',
                },
                body: selectedFile,
            });
    
            if (response.ok) {
                console.log("Upload successful:", azureUploadUrl);
                return azureUploadUrl;
                // setAlertType("Success");
                // setAlertMessage("圖片上傳成功");
                // setShowAlert(true);
            } else {
                const errorText = await response.text();
                console.error("Upload failed:", response.statusText, errorText);
                setAlertType("Error");
                setAlertMessage("圖片上傳失敗，請再試一次");
                setShowAlert(true);
            }
        } catch (error) {
            console.error("Error:", error);
            setAlertType("Error");
            setAlertMessage("上傳過程中發生錯誤");
            setShowAlert(true);
        }
    };
    

    const content = (

        <>
            {isShowAlert && <AlertMessage ismessageType={isAlertType} isAlertMessage={isAlertMessage} />}
            {isloading && <DeafultLoadingBar />}

            <div className={css.buttonContainer}>
                <button className={css.ReturnListBtn} onClick={() => setEditMode && setEditMode(null)}>
                    <i className={`fi fi-br-angle-double-left ${css.returntoListIcon}`} />
                    Back To Product List
                </button>
            </div>

            <section className={css.MainSection}>
                <div className={css.baseProductInfoDiv}>

                    <div className={css.ProductImageDiv}>
                        {fileData.fileInput1.previewUrl && fileData.fileInput1.previewUrl.length >= 0 ? (
                            <>
                                <img src={fileData.fileInput1.previewUrl} alt="Product_main_Image" />
                                <button className={css.PhotoProductBtn} onClick={() => handleFileRemove('fileInput1')}>
                                    <i className={`fi fi-rr-file-upload ${css.EditPhotoIcon}`} />
                                    Remove
                                </button>
                            </>
                        ) : (
                            <>
                                {data?.product_type_img ? (
                                    <img src={data.product_type_img} alt="Product_main_Image" />
                                ) : (
                                    <img src={DefaultProductImg} alt="Product_main_Image" />
                                )}
                                <button className={css.PhotoProductBtn} onClick={() => console.log("This function not ready.")}>
                                    <input type="file" accept="image/*" onChange={(e) => handleFileChange(e, 'fileInput1')} />
                                    <i className={`fi fi-rr-file-upload ${css.EditPhotoIcon}`} />
                                    Edit
                                </button>
                            </>
                        )}
                    </div>

                    <div className={css.baseProductInfo1}>

                        <div className={css.baseInfoItem}>
                            <label>Type Code</label>
                            <input type="text" name="product_type_code" value={data?.product_type_code} onChange={handleInputChange} disabled={true} />
                        </div>

                        <div className={css.baseInfoItem}>
                            <label>Product Type </label>
                            <input type="text" name="product_type_name" value={data.product_type_name ?? ''} onChange={handleInputChange} />
                        </div>

                        <div className={css.baseInfoItem}>
                            <label>Product Description</label>
                            <input type="text" name="product_type_desc" value={data?.product_type_desc} onChange={handleInputChange} />
                        </div>


                        <div className={css.baseInfoItem}>
                            <label>Status</label>
                            <select name="product_type_status" value={data?.product_type_status} onChange={handleInputChange}>
                                <option value="Y">開啟</option>
                                <option value="N">關閉</option>
                            </select>
                        </div>
                    </div>
                </div>

            </section>

            <section className={css.BottomSection}>
                <div className={css.SaveBtnContainer}>
                    {/* <button className={css.BottomBtn} onClick={() => setMode(4)}>
                        <i className="fi fi-rs-key" />
                        Reset Password
                    </button> */}

                    <button className={css.BottomBtn} onClick={() => uploadImageToAzureUsingREST()}>
                        <i className="fi fi-br-user-add" />
                        Test Upload
                    </button>

                    <button className={css.BottomBtn} onClick={() => SaveProductTypeData()}>
                        <i className="fi fi-br-user-add" />
                        Save
                    </button>
                </div>
            </section>
        </>
    )
    return content
}

export default C0211EditProductTypeDetail;