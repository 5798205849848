import axios from 'axios'
import { Cookies } from 'react-cookie';
import { SystemContextType } from '../Class/SystemPara';

const cookies = new Cookies();


export const FetchSystemData = async (systemContext: SystemContextType) => {
    let ACID = cookies.get('ACID')
    let token = cookies.get('token')

    if (ACID === "c0211") {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/c0502_vba/product/InsertProductType`, {
                headers: { Authorization: `Bearer ${token}` }
            });

            const resPermission = await axios.post(`${process.env.REACT_APP_API_URL}/c0502_vba/user/GetUserPermission`, {
            },{
                headers: { Authorization: `Bearer ${token}` }
            });

            if (resPermission.status === 200) {
                systemContext.setFunctionPermission(resPermission.data.UserPermission)
                console.log(resPermission.data.UserPermission)
            }
        
            if (res.status === 200) {
                systemContext.setLoginStatus(true)
            }
        } catch (error) {
            console.log(error)
        }
    } else if (ACID === "c0000") {
        try {

            console.log("Running FetchSystemData")
    
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/menu/GetFunction`, {}, {
                headers: { Authorization: `Bearer ${token}` }
            });
    
            if (res.status === 200) {
                systemContext.setFunctionArr(res.data.permissions)
                systemContext.setLoginStatus(true)
            }
    
        } catch (error) {
            console.log(error)
        }
    } else if (ACID === "c0217") {
        try {

            const loginres = await axios.post(`${process.env.REACT_APP_API_URL}/c0217_vba/user/GetUserDataByToken`, { }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            if (loginres.status === 200) {
                systemContext.setLoginStatus(true)
            }

        } catch (error) {
            console.log(error)
        } finally {
        }
    }





}

