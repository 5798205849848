import css from '../../../css/C0217/Dashboard.module.css';

import ShopBarChart from '../../../Components/C0217/Main/ShopBarChar';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { IC0217ShopList, ISalesAnalysisData, ISalesAnanlysisGraph } from '../../../Interface/interface';
import { set } from 'rsuite/esm/utils/dateUtils';

const C0217DashboardPage = () => {

    const [isReportType, setReportType] = useState<number>(1)
    const [isDataRange, setDataRange] = useState<number>(1)
    const [isDisplayType, setDisplayType] = useState<number>(1)

    const [isBarchartLabel, setBarChartLabel] = useState<string[]>([])


    const [isAllData, setAllData] = useState<ISalesAnanlysisGraph[]>([])

    const [isFoodPandaData, setFoodPandaData] = useState<ISalesAnalysisData[]>([])
    const [isFoodPandaDataTop10This, setFoodPandaDataTop10This] = useState<number[]>([])
    const [isFoodPandaDataTop10Last, setFoodPandaDataTop10Last] = useState<number[]>([])
    const [isFoodPandaShopLable, setFoodPandaDataShopLable] = useState<string[]>([])

    const [isDeliverooData, setDeliverooData] = useState<ISalesAnalysisData[]>([])
    const [isDeliverooDataTop10This, setDeliverooDataTop10This] = useState<number[]>([])
    const [isDeliverooDataTop10Last, setDeliverooDataTop10Last] = useState<number[]>([])
    const [isDeliverooShopLable, setDeliverooDataShopLable] = useState<string[]>([])

    const [isKeetaData, setKeetaData] = useState<ISalesAnalysisData[]>([])
    const [isKeetaDataTop10This, setKeetaDataTop10This] = useState<number[]>([])
    const [isKeetaDataTop10Last, setKeetaDataTop10Last] = useState<number[]>([])
    const [isKeetaShopLable, setKeetaDataShopLable] = useState<string[]>([])

    const [isOpenriceData, setOpenriceData] = useState<ISalesAnalysisData[]>([])
    const [isOpenriceDataTop10This, setOpenriceDataTop10This] = useState<number[]>([])
    const [isOpenriceDataTop10Last, setOpenriceDataTop10Last] = useState<number[]>([])
    const [isOpenriceShopLable, setOpenriceDataShopLable] = useState<string[]>([])

    const [shopdata, setShopData] = useState<IC0217ShopList[]>([])

    useEffect(() => {
        getShopList()
        getShopUploadData()
    }, [])

    useEffect(() => {
        ConvterData()
    }, [isAllData])

    const getShopList = async () => {
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/c0217_vba/order/LoadShopList`, {}, {})

            if (res.status === 200) {
                setShopData(res.data['Shop list'])
                setBarChartLabel(
                    Array.from(new Set(res.data['Shop list'].map((item: IC0217ShopList) => item.shop_code)))
                );
            }

        } catch (error) {
            console.log(error)
        } finally {
        }
    }

    const getShopUploadData = async () => {
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/c0217_vba/order/GetSalesAnalysisGraph`, {}, {})

            if (res.status === 200) {
                
                setFoodPandaData(res.data["Foodpanda_SalesAnalysis"])
                setDeliverooData(res.data["Deliveroo_SalesAnalysis"])
                setKeetaData(res.data["Keeta_SalesAnalysis"])
                setOpenriceData(res.data["Openrice_SalesAnalysis"])
                setAllData(res.data)
            }

        } catch (error) {
            console.log(error)
        } finally {
            
        }
    }

    function SearchShopFc() {
        const input = document.getElementById("SearchShopTxt") as HTMLInputElement;
        const filter = input.value.toUpperCase();
        const table = document.getElementById("ShopListTable") as HTMLTableElement;
        const tr = table.getElementsByTagName("tr");

        for (let i = 0; i < tr.length; i++) {
            const tdShopCode = tr[i].getElementsByTagName("td")[0];
            const tdShopName = tr[i].getElementsByTagName("td")[1];

            if (tdShopCode || tdShopName) {
                const txtShopCodeValue = tdShopCode.textContent || tdShopCode.innerText;
                const txtShopNameValue = tdShopName.textContent || tdShopName.innerText;
                // Check if either Staff ID or Username matches the filter
                if (txtShopCodeValue.toUpperCase().indexOf(filter) > -1 ||
                    txtShopNameValue.toUpperCase().indexOf(filter) > -1) {
                    tr[i].style.display = ""; // Show the row if match is found
                } else {
                    tr[i].style.display = "none"; // Hide the row if no match is found
                }
            }
        }
    }

    const RandomData = () => {
        const randomnumber: number[] = [];
        for (let i = 0; i < 10; i++) {
            const randomNum = Math.floor(Math.random() * (83520 - 25200 + 1)) + 25200;
            randomnumber.push(randomNum);
          }
        return randomnumber;
    }

    const ConvterData = () => {

        if (isFoodPandaData && isFoodPandaData.length > 0) {
            setFoodPandaDataTop10This(
                Array.from(new Set(
                    isFoodPandaData
                        .map((item: ISalesAnalysisData) => item.this_sales)
                        .slice(0, 10) // Get top 10
                ))
            );
            setFoodPandaDataTop10Last(
                Array.from(new Set(
                    isFoodPandaData
                        .map((item: ISalesAnalysisData) => item.last_sales)
                        .slice(0, 10) // Get top 10
                ))
            );
            setFoodPandaDataShopLable(
                Array.from(new Set(
                    isFoodPandaData
                        .map((item: ISalesAnalysisData) => item.shop_code)
                        .slice(0, 10) // Get top 10
                ))
            );
        }
    
        if (isDeliverooData && isDeliverooData.length > 0) {
            setDeliverooDataTop10This(
                Array.from(new Set(
                    isDeliverooData
                        .map((item: ISalesAnalysisData) => item.this_sales)
                        .slice(0, 10) // Get top 10
                ))
            );
            setDeliverooDataTop10Last(
                Array.from(new Set(
                    isDeliverooData
                        .map((item: ISalesAnalysisData) => item.last_sales)
                        .slice(0, 10) // Get top 10
                ))
            );
            setDeliverooDataShopLable(
                Array.from(new Set(
                    isDeliverooData
                        .map((item: ISalesAnalysisData) => item.shop_code)
                        .slice(0, 10) 
                ))
            );
        }
    
        if (isKeetaData && isKeetaData.length > 0) {
            setKeetaDataTop10This(
                Array.from(new Set(
                    isKeetaData
                        .map((item: ISalesAnalysisData) => item.this_sales)
                        .slice(0, 10) // Get top 10
                ))
            );
            setKeetaDataTop10Last(
                Array.from(new Set(
                    isKeetaData
                        .map((item: ISalesAnalysisData) => item.last_sales)
                        .slice(0, 10) // Get top 10
                ))
            );
            setKeetaDataShopLable(
                Array.from(new Set(
                    isKeetaData
                        .map((item: ISalesAnalysisData) => item.shop_code)
                        .slice(0, 10) 
                ))
            );
        }
    
        if (isOpenriceData && isOpenriceData.length > 0) {
            setOpenriceDataTop10This(
                Array.from(new Set(
                    isOpenriceData
                        .map((item: ISalesAnalysisData) => item.this_sales)
                        .slice(0, 10) // Get top 10
                ))
            );
            setOpenriceDataTop10Last(
                Array.from(new Set(
                    isOpenriceData
                        .map((item: ISalesAnalysisData) => item.last_sales)
                        .slice(0, 10) // Get top 10
                ))
            );
            setOpenriceDataShopLable(
                Array.from(new Set(
                    isOpenriceData
                        .map((item: ISalesAnalysisData) => item.shop_code)
                        .slice(0, 10) 
                ))
            );
        }
    };


    const content = (
        <>
            <section className={css.MainSection}>

                {/* <div className={css.DataRangeControlDiv}>
                    <button
                        style={isDataRange === 1 ? { borderBottom: '3px #FFA600 solid' } : {}}
                        onClick={() => setDataRange(1)}>
                        Today
                    </button>
                    <button
                        style={isDataRange === 2 ? { borderBottom: '3px #FFA600 solid' } : {}}
                        onClick={() => setDataRange(2)}>
                        Last 7 Days
                    </button>
                    <button
                        style={isDataRange === 3 ? { borderBottom: '3px #FFA600 solid' } : {}}
                        onClick={() => setDataRange(3)}>
                        Last 30 Days
                    </button>
                </div> */}

                <div className={css.MainChartDiv}>

                    <div className={css.AllChartDiv}>

                        <div className={css.EachDisplayChartDiv}>
                            <p>FoodPanda</p>
                            <ShopBarChart displayDataLabel={isFoodPandaShopLable} displayCData={isFoodPandaDataTop10This} displayLData={isFoodPandaDataTop10Last}/>
                            {/* <ShopBarChart /> */}
                        </div>

                        <div className={css.EachDisplayChartDiv}>
                            <p>Deliveroo</p>
                            <ShopBarChart displayDataLabel={isDeliverooShopLable} displayCData={isDeliverooDataTop10This} displayLData={isDeliverooDataTop10Last}/>
                            {/* <ShopBarChart /> */}
                        </div>

                        <div className={css.EachDisplayChartDiv}>
                            <p>Keeta</p>
                            <ShopBarChart displayDataLabel={isKeetaShopLable} displayCData={isKeetaDataTop10This} displayLData={isKeetaDataTop10Last}/>
                            {/* <ShopBarChart /> */}
                        </div>

                        <div className={css.EachDisplayChartDiv}>
                            <p>Openrice</p>
                            <ShopBarChart displayDataLabel={isOpenriceShopLable} displayCData={isOpenriceDataTop10This} displayLData={isOpenriceDataTop10Last}/>
                            {/* <ShopBarChart /> */}
                        </div>
                    </div>

                    <div className={css.ShopCodeLiseDiv}>
                        <input type="text" id="SearchShopTxt" onKeyUp={SearchShopFc} className={css.SeachShopText} placeholder="Search for anything" ></input>
                        <table className={css.ShopTable} id="ShopListTable">
                            <thead>
                                <tr>
                                    <th>Shop Code</th>
                                    <th>Shop brand</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    shopdata.map((item: IC0217ShopList, index: number) => {
                                        return (
                                            <tr key={index}>
                                                <td>{item.shop_code}</td>
                                                <td>{item.shop_brand}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>

                    </div>
                </div>

            </section>
        </>
    )
    return content
}

export default C0217DashboardPage;