import { useState } from "react"
import css from "../../css/loadingbar.module.css"


import C0211Logo from "../../global/C0211/紹香園Logo.png"
import C0217Logo from "../../global/C0217/ACLogo.png"
import DefaultLogo from '../../global/DefaultLooading2.png'
import loadingbarSVG from "../../global/loadingBar.svg"


import C0211loadingbarSVG from "../../global/C0211/C0211LoadingBar.svg"
import { ILoadingBar } from "../../Interface/interface"

const DeafultLoadingBar: React.FC<ILoadingBar> = ({ 
    isACID 
}) => {

    const routerACIDLogo = () => {
        switch (isACID) {
            case "C0217":
                return <img className={css.AClogo} src={DefaultLogo} alt="DefaultLogo" />
            case "C0211":
                return <img className={css.AClogo} src={C0211Logo} alt="C0211Logo" />
        default:
            return <img className={css.AClogo} src={C0211Logo} alt="C0211Logo" />
        }
    }

    const content = (
        <section className={css.loadingSection}>
            <div className={css.loadingbardiv}>
                <img className={css.loadingBar} src={C0211loadingbarSVG} alt="Loading" />
                {routerACIDLogo()}
                
            </div>
        </section>
    )
    return content
}

export default DeafultLoadingBar