import { Helmet, HelmetProvider } from "react-helmet-async"
import { Outlet } from "react-router-dom"
import NavBar from "../../Components/C0211/Main/Navbar"
import css from '../../css/C0211/mainLayout.module.css'

import ACLogo from '../../global/C0211/SHYLogo.png'
import ChangeColor from '../../Components/ChangeColor'
import { useEffect, useContext } from "react"
import { SystemContext } from "../../Class/SystemPara"
import { FetchSystemData } from "../../Interface/Const"

const C0211DashLayout = () => {
    const systemContext = useContext(SystemContext);

    useEffect(() => {
        FetchSystemData(systemContext)
    }, [])

    const content = (
        <body>
            <HelmetProvider>
                <div>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>紹香園 - 控制台</title>
                    </Helmet>
                    <div className={css.DashLayout}>
                        <div className={css.logo}>
                            <img src={ACLogo} alt="紹香園 Logo" />
                        </div>

                        <div className={css.navbar}>
                            <NavBar />
                        </div>

                        <div className={css.userinfo}>
                            <ChangeColor />
                        </div>

                        <div className={css.Outlet}>
                            <Outlet />
                        </div>
                    </div>

                </div>
            </HelmetProvider>
        </body>
    )

    return content
}

export default C0211DashLayout