import { Link } from 'react-router-dom'
import css from '../../../css/C0217/NavBar.module.css'

const c0217Navbar = () => {
    const content = (
        <>
            <section className={css.MainSection}>
                <div className={css.EachTypeItem}>
                    <Link to="/C0217/"><i className={`fi fi-rs-home ${css.iconStyles}`} /><p>Dashboard</p></Link>
                </div>
                <div className={css.EachTypeItem}>
                    <Link to="/C0217/FunctionList"><i className={`fi fi-sr-list ${css.iconStyles}`} /><p>Function</p></Link>
                </div>
                <div className={css.EachTypeItem}>
                    <Link to="/C0217/Setting"><i className={`fi fi-rs-settings ${css.iconStyles}`} /><p>Setting</p></Link>
                </div>

            </section>

        </>
    )

    return content
}

export default c0217Navbar