import { use } from 'i18next'
import css from '../../../css/C0211/Dashborad.module.css'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { Cookies } from 'react-cookie'
import { IC0211Shop } from '../../../Interface/interface'

import ShopBarChart from '../../../Components/C0211/Chart/ShopBarChart'
const cookies = new Cookies()

const C0211DashboardPage = () => {

    const token = cookies.get('token')

    const [isShopData, setShopData] = useState<IC0211Shop[]>([])
    const [isBarchartLabel, setBarChartLabel] = useState<string[]>([])
    const [isBarchartData, setBarChartData] = useState<number[]>([])

    useEffect(() => {
        getAPIShopList()
    }, [])

    const getAPIShopList = async () => {

        console.log(token)
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/c0502_vba/shop/SelectAllFromShop`, {
                headers: { Authorization: `Bearer ${token}` },
            })

            if (res.status === 200) {
                setShopData(res.data.Shop)
                setBarChartLabel(
                    Array.from(new Set(res.data.Shop.map((item: IC0211Shop) => item.shop_code)))
                );
                setBarChartData(
                    Array.from(new Set(res.data.Shop.map((item: IC0211Shop) => item.shop_id)))
                );
                console.log(res.data.Shop)
            }

        } catch (error) {
            console.log(error)
        } finally {
            console.log('finally')
        }
    }

    const content = (
        <>
            <section className={css.MainSection}>
                <div className={css.Test}>
                    <ShopBarChart
                        displayDataLabel={isBarchartLabel}
                        displayData={isBarchartData} />
                </div>
            </section>
        </>
    )
    return content
}

export default C0211DashboardPage
