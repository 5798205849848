import { Link } from 'react-router-dom';
import NBcss from '../css/NavBar.module.css'
import Logo from '../global/logo.jpg'
import { Cookies } from 'react-cookie';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Hamburger from 'hamburger-react'
import { SystemContext } from "../Class/SystemPara"

//page
import MediaNavBar from './MediaNavBar';

const cookies = new Cookies();



const Navbar = (props: any) => {

    const systemContext = useContext(SystemContext);

    const [isOpen, setOpen] = useState(false)
    const { t, i18n } = useTranslation();
    const [login, IsLogin] = useState(false)

    const Logout = () => {
        cookies.remove('token');
        systemContext.setLoginStatus(false);
        window.location.replace(`${process.env.REACT_APP_WEBSITE_URL}/`);
    }

    useEffect(() => {
        IsLogin(cookies.get('Login_status'))

    }, []);

    const dashboardRouter = () => {
        const getACID = cookies.get('ACID');
        let dashboardLink = null;

        console.log(getACID);

        switch (getACID) {
            case "c0000":
                dashboardLink = (
                    <li><Link className={NBcss.active} to="/dash">{t('DashBoard')}</Link></li>
                );
                break;
            case "c0211":
                dashboardLink = (
                    <li><Link className={NBcss.active} to="/C0211">{t('DashBoard')}</Link></li>
                );
                break;
            case "c0217":
                dashboardLink = (
                    <li><Link className={NBcss.active} to="/C0217">{t('DashBoard')}</Link></li>
                );
                break;
            default:
                dashboardLink = null;
        }

        return dashboardLink;
    };

    // useEffect(() => {
    //     const lng = navigator.language
    //     setLng(navigator.language)
    //     i18n.changeLanguage(lng)
    // }, []);

    const content = (
        <section className={NBcss.MainSection}>
            <div className={NBcss.NavbarMain}>

                <div className={NBcss.LogoImg}>
                    <img src={Logo} alt="logo" className={NBcss.Img}></img>
                </div>

                <div className={NBcss.Slogan}>
                    <p>Work smart ‧ Not hard</p>
                </div>

                <div className={NBcss.NavbarMenu} id="navMenu">
                    {/* <button className={NBcss.MediaMenu}>&#9776;</button> */}
                    <div className={NBcss.MediaMenu}>
                        <Hamburger toggled={isOpen} toggle={setOpen} size={50} />
                    </div>
                    <ul id={NBcss.navMenu}>
                        <li><Link className={NBcss.active} to="/">{t('HomePage')}</Link></li>
                        <li><Link className={NBcss.active} to="/contact-us">{t('ContactUS')}</Link></li>
                        <li><Link className={NBcss.active} to="/PCSystem">{t('PCSoftWare')}</Link></li>
                        <li><Link className={NBcss.active} to="/PhoneSystem">{t('PhoneSoftWare')}</Link></li>
                        {/* {systemContext.systemData.isLogin === true && <li><Link className={NBcss.active} to="/dash">{t('DashBoard')}</Link></li>} */}
                        {systemContext.systemData.isLogin === true && dashboardRouter()}
                        {systemContext.systemData.isLogin === true ? <li><Link className={NBcss.active} to="/Login" onClick={Logout}>{t('Logout')}</Link></li> : <li><Link className={NBcss.active} to="/Login">{t('Login')}</Link></li>}
                    </ul>
                </div>
                {isOpen ? (<MediaNavBar />) : null}

            </div>
        </section>
    )
    return content
};

export default Navbar;
