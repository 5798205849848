import { Link } from 'react-router-dom'
import css from '../../../css/C0211/NavBar.module.css'
import { Cookies } from "react-cookie";
import { useContext } from 'react';
import { SystemContext } from '../../../Class/SystemPara';
const cookies = new Cookies();

const C0211Navbar = () => {

    const { systemData, setFunctionArr, setLoginStatus} = useContext(SystemContext);

    const FncLogin = () => {
        cookies.remove('token');
        setLoginStatus(false)
    }

    const content = (
        <>
            <section className={css.MainSection}>
                <div className={css.EachTypeItem}>
                    <Link to="/C0211/"><i className={`fi fi-rr-dashboard ${css.iconStyles}`} /><p>Dashboard</p></Link>
                </div>
                <div className={css.EachTypeItem}>
                    <Link to="/C0211/FunctionList"><i className={`fi fi-rs-function-process ${css.iconStyles}`} /><p>Function</p></Link>
                </div>
                <div className={css.EachTypeItem}>
                    <Link to="/C0211/Setting"><i className={`fi fi-rs-settings ${css.iconStyles}`} /><p>Setting</p></Link>
                </div>
                <div className={css.EachTypeItem}>
                    <Link to="/" onClick={FncLogin}><i className={`fi fi-ss-sign-out-alt ${css.iconStyles}`} /><p>Logout</p></Link>
                </div>

            </section>

        </>
    )

    return content
}

export default C0211Navbar