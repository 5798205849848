import { IC0217DownloadReportProps, IC0217RawDataA, IC0217RawDataB, IC0217RawDataSummaryReport, IC0217RawSummaryData } from "../../../Interface/interface"
import { useEffect, useState } from "react"
import axios from "axios"
import css from '../../../css/C0217/GenerateReport.module.css'
import FPSummaryReport from './Report/FPRawDataSummary'
import DRSummartReport from './Report/DeliverooRawDataSummary'
import KTSummartReport from './Report/KeetaRawDataSummary'
import RawData from "./Report/OrderRawData"

import foodpandaLogo from "../../../global/C0217/FoodPandaLogo.png"
import deliverooLogo from "../../../global/C0217/deliverooLogo.png"
import keetaLogo from "../../../global/C0217/KeetaLogo.png"
import { set } from "rsuite/esm/utils/dateUtils"

const C0217GenerateReport: React.FC<IC0217DownloadReportProps> = ({
    setShowAlert,
    setAlertType,
    setAlertMessage,
    setLoading,
    setStage,
    isFilterData,
    selectedShops,
    keywords,
}) => {

    const [rawSummaryData, setRawSummaryData] = useState<IC0217RawDataSummaryReport | null>(null)
    const [rawDataA, setRawDataA] = useState<IC0217RawDataA[]>([])
    const [rawDataB, setRawDataB] = useState<IC0217RawDataB[]>([])

    const [isreporttype, setIsReportType] = useState<string | null>(null)
    const [isselectplatform, setIsSelectPlatform] = useState<string | null>(null)

    useEffect(() => {
        setLoading && setLoading(true)
        LoadReportRouter()
        setIsSelectPlatform(isFilterData?.PlatformType ? isFilterData.PlatformType[0] || null : null)
    }, [])

    useEffect(() => {
        console.log("Raw Data A and B")
        // console.log(rawDataA)
        // console.log(rawDataB)   
    }, [rawDataA, rawDataB])

    const LoadReportRouter = () => {
        console.log(isFilterData?.ReportType)
        if (isFilterData?.ReportType?.includes("Raw Data Summary")) {
            setIsReportType("SummaryReport")
            cAPIRawDataSummaryReport()
        }

        if (isFilterData?.ReportType?.includes("RAWData")) {
            setIsReportType("RawDataAandB")
            cAPIRawDataReport()
        }
    }

    const convertDateFormat = (date: string | undefined) => {
        if (date === undefined) return ""
        const dateArray = date.split("-")
        return `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`
    }

    const convertShopList = (selectedShops: Set<number> | undefined) => {
        if (selectedShops === undefined) return []
        let shoplist: number[] = []
        selectedShops.forEach(shop => {
            shoplist.push(shop)
        })
        return shoplist
    }

    const cAPIRawDataSummaryReport = async () => {
        console.log("Calling cAPIRawDataSummaryReport")

        const postdata = {
            StartDate: convertDateFormat(isFilterData?.StartDate),
            EndDate: convertDateFormat(isFilterData?.EndDate),
            Keywords: isFilterData?.FileNameKeyword,
            SupplierCode: convertShopList(selectedShops),
            OrderCode_Keywords: isFilterData?.OrderCodeKeyword,
            Category_Keywords: keywords,
            FileNameFilter: isFilterData?.DateFilterBy,
            Table_selection: isFilterData?.PlatformType,
            OrderCode_sw_check: isFilterData?.OrderCodeSWCheck,
        }

        console.log(postdata)

        try {
            //const res = await axios.post(`http://192.168.0.128:8000/c0217_vba/order/GetRawDataSummaryNew1023`, {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/c0217_vba/order/GetRawDataSummaryNew1023`, postdata, {})

            if (res.status === 200) {
                setRawSummaryData(res.data)
            }

        } catch (error) {
            console.log(error)

            if (axios.isAxiosError(error) && error.response?.status === 400) {
                setShowAlert && setShowAlert(true)
                setAlertType && setAlertType("error")
                setAlertMessage && setAlertMessage("Server Timer Out. Please try again later.")
            }
        } finally {
            console.log("cAPIRawDataSummaryReport")
            setLoading && setLoading(false)
        }
    }

    const cAPIRawDataReport = async () => {

        try {

            const postData = {
                StartDate: convertDateFormat(isFilterData?.StartDate),
                EndDate: convertDateFormat(isFilterData?.EndDate),
                Keywords: isFilterData?.FileNameKeyword,
                SupplierCode: convertShopList(selectedShops),
                OrderCode_Keywords: isFilterData?.OrderCodeKeyword,
                Category_Keywords: keywords,
                FileNameFilter: isFilterData?.DateFilterBy,
                Table_selection: isFilterData?.PlatformType,
            }
            console.log(postData)

            //const rawDataA = await axios.post(`http://192.168.68.182:8000/c0217_vba/order/DownloadRawDataANew1029`, {
            const rawDataA = await axios.post(`${process.env.REACT_APP_API_URL}/c0217_vba/order/DownloadRawDataANew1029`, {
                StartDate: convertDateFormat(isFilterData?.StartDate),
                EndDate: convertDateFormat(isFilterData?.EndDate),
                Keywords: isFilterData?.FileNameKeyword,
                SupplierCode: convertShopList(selectedShops),
                OrderCode_Keywords: isFilterData?.OrderCodeKeyword,
                Category_Keywords: keywords,
                FileNameFilter: isFilterData?.DateFilterBy,
                Table_selection: isFilterData?.PlatformType,
                OrderCode_sw_check: isFilterData?.OrderCodeSWCheck,
            }, {})

            //const rawDataB = await axios.post(`http://192.168.68.182:8000/c0217_vba/order/DownloadRawDataANew1029`, {
            const rawDataB = await axios.post(`${process.env.REACT_APP_API_URL}/c0217_vba/order/DownloadRawDataBNew1023`, {
                StartDate: convertDateFormat(isFilterData?.StartDate),
                EndDate: convertDateFormat(isFilterData?.EndDate),
                Keywords: isFilterData?.FileNameKeyword,
                SupplierCode: convertShopList(selectedShops),
                OrderCode_Keywords: isFilterData?.OrderCodeKeyword,
                Category_Keywords: keywords,
                FileNameFilter: isFilterData?.DateFilterBy,
                Table_selection: isFilterData?.PlatformType,
                OrderCode_sw_check: isFilterData?.OrderCodeSWCheck,
            }, {})

            if (rawDataA.status === 200) {
                setRawDataA(rawDataA.data.Foodpanda_A)
            }

            if (rawDataB.status === 200) {
                setRawDataB(rawDataB.data.Foodpanda_B)
            }

        } catch (error) {
            console.log(error)

            if (axios.isAxiosError(error) && error.response?.status === 400) {
                setShowAlert && setShowAlert(true)
                setAlertType && setAlertType("error")
                setAlertMessage && setAlertMessage("Server Timer Out. Please try again later.")
            }
        } finally {
            console.log("cAPIRawData")
            setLoading && setLoading(false)
        }
    }

    const switchFilterByList = (value: number) => {
        switch (value) {
            case 1:
                return "Billing Date"
            case 2:
                return "File Name"
            default:
                return ""
        }
    }

    const routerPlatform = (platform: string) => {
        switch (platform) {
            case "foodpanda":
                return foodpandaLogo
            case "deliveroo":
                return deliverooLogo
            case "keeta":   
                return keetaLogo
            default:
                return ""
        }
    }

    const content = (
        <>
            <section className={css.MainSection}>
                <div className={css.ReportFilter}>
                    <div className={css.EachFilterItem}>
                        <label>Start Date: </label>
                        <p>{isFilterData?.StartDate}</p>
                    </div>

                    <div className={css.EachFilterItem}>
                        <label>End Date: </label>
                        <p>{isFilterData?.EndDate}</p>
                    </div>

                    {isFilterData?.DateFilterBy && isFilterData?.DateFilterBy !== null && (
                        <div className={css.EachFilterItem}>
                            <label>Date Filter By: </label>
                            <p>{switchFilterByList(isFilterData?.DateFilterBy)}</p>
                        </div>
                    )}


                    {isFilterData?.PlatformType && isFilterData?.PlatformType !== null && (
                        <div className={css.EachFilterItem}>
                            <label>Platform Type: </label>
                            {isFilterData?.PlatformType.map((item, index) => (
                                <img key={index} src={routerPlatform(item)} alt={item} />
                            ))}
                        </div>
                    )}

                </div>


                {isreporttype === "SummaryReport" && (
                    <>
                        {isFilterData?.PlatformType && isFilterData?.PlatformType?.length > 1 && (
                            <div className={css.PlatformSelection}>
                                <label>Select Platform: </label>
                                <select
                                    onChange={(e) => setIsSelectPlatform(e.target.value)}
                                    value={isselectplatform || ""}
                                >
                                    {isFilterData?.PlatformType.map((item, index) => (
                                        <option key={index} value={item}>{item}</option>
                                    ))}
                                </select>
                            </div>
                        )}

                        {isselectplatform === "foodpanda" && (
                            <FPSummaryReport
                                rawSummaryData={rawSummaryData}
                            />
                        )}

                        {isselectplatform === "deliveroo" && (
                            <DRSummartReport
                                rawSummaryData={rawSummaryData}
                            />
                        )}

                        {isselectplatform === "keeta" && (
                            <KTSummartReport
                                rawSummaryData={rawSummaryData}
                            />
                        )}
                    </>
                )}

                {isreporttype === "RawDataAandB" && (
                    <>
                        <RawData
                            rawdataA={rawDataA}
                            rawdataB={rawDataB}
                        />
                    </>
                )}



                <div className={css.ControlBtnDiv}>

                    <div className={css.ControlBtn}>
                        <button className={css.SelectionBackBtn} onClick={() => setStage && setStage(1)}>
                            <i className="fi fi-br-left" />
                            Back
                        </button>
                    </div>

                </div>

            </section>
        </>
    )
    return content
}

export default C0217GenerateReport