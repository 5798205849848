import ReactDOM from 'react-dom';
import { useState, useEffect } from 'react';
import './index.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { UserContext } from '../src/Class/UserPara'
import './i18n.tsx'
import axios from 'axios';
import { IC0211WebsitePermission, IFunction, IUser } from './Interface/interface';
import { Cookies } from 'react-cookie';
import { SystemContext, SystemData } from './Class/SystemPara';
//Pages
import PublicLayout from './Layout/PublicLayout';
import DashLayout from './Layout/DashLayout';
import TestingLayout from './Layout/TestingLayout';
import HomePage from './Pages/HomePage';
import ContactPage from './Pages/ContactPage';
import PCSystemPage from './Pages/PCSystemPage';
import PhoneSystem from './Pages/PhoneSystem';
import LoginPage from './Pages/LoginPage';
import DashHomePage from './Pages/DashHomePage';
import RaceRankPage from './Pages/RaceRankPages';
import NewRaceRank from './Components/NewRaceRank';
import ProfileSettingPage from './Pages/ProfileSettingPage';
import UserListPage from './Pages/UserListPage';
import GPSRecordPage from './Pages/GPSRecordPage';
import ShopListPage from './Pages/ShopListPage';
import EditShopPage from './Pages/EditShopPage';
import RosterPage from './Pages/RosterPage';
import TCPage from './Pages/TCPage'
import ACFunctionListPage from './Pages/ACFunctionListPage';

//======> Common Page
import DashSettingPage from './Pages/Common/DashSettingPage'

//======> C0533 Page
import MainLayout from './Layout/C0533/MainLayout'
import HomePageC0533 from './Pages/C0533/HomePage';
import AboutHKRUS from './Pages/C0533/AboutUs/About_hkrsu';
import OurCrewPage from './Pages/C0533/AboutUs/Our_Crew'
import CoursePage from './Pages/C0533/Classes/CoursePage';
import AcesPage from './Pages/C0533/Classes/AcesPage';
import PerformanceteamPage from './Pages/C0533/PerformanceteamPage';
import UnionCupPage from './Pages/C0533/Competitions/UnionCupPage';
import AWADRSInternationPage from './Pages/C0533/Awards/AWADRSInternationPage';
import AWARDSPage from './Pages/C0533/Awards/AWARDSPage';
import SalesPage from './Pages/C0533/SalesPage';
import ContactUsPage from './Pages/C0533/ContactUsPage';
import SkillTestPage from './Pages/C0533/SkillTestPage';
import BemisSampleRequestFormPage from './Pages/C0281/BemisSampleRequestFormPage';

//======> C0211 Page
import C0211MainLayout from './Layout/C0211/DashLayout'
import C0211Error404 from './Pages/C0211/Main/NotFoundPage'
import C0211Dashboard from './Pages/C0211/Main/DashboardPage'
import C0211FunctionListPage from './Pages/C0211/Main/FunctionListPage'
import C0211TestPage from './Pages/C0211/TestPage'
import C0211UserList from './Pages/C0211/HumanResources/UserListPage'
import C0211ProductPage from './Pages/C0211/WareHouse/ProductPage'

//======> C0217 Page
import C0217MainLayout from './Layout/C0217/DashLayout'
import C0217Dashboard from '../src/Pages/C0217/Main/DashboardPage'
import C0217FunctionListPage from '../src/Pages/C0217/Main/FunctionListPage'
import C0217DownloadReportPage from './Pages/C0217/Accounting/DownloadReport';


const cookies = new Cookies();


const router = createBrowserRouter([
  {
    path: '/',
    element: <PublicLayout />,
    children: [
      { path: '*', element: <HomePage /> },
      { path: '/', element: <HomePage /> },
      { path: '/contact-us', element: <ContactPage /> },
      { path: '/PCSystem', element: <PCSystemPage /> },
      { path: '/PhoneSystem', element: <PhoneSystem /> },
      { path: '/Login', element: <LoginPage /> },
      { path: '/Tc', element: <TCPage /> },
    ]
  },
  {
    path: '/dash',
    element: <DashLayout />,
    children: [
      { path: '/dash/', element: <ACFunctionListPage /> },
      { path: '/dash/ProductList', element: <DashHomePage /> },
      { path: '/dash/RaceRank', element: <RaceRankPage /> },
      { path: '/dash/Setting', element: <ProfileSettingPage /> },
      { path: '/dash/UserList', element: <UserListPage /> },
      { path: '/dash/GPSReocrd', element: <GPSRecordPage /> },
      { path: '/dash/ShopList', element: <ShopListPage /> },
      { path: '/dash/EditShop', element: <EditShopPage /> },
      { path: '/dash/RosterRecord', element: <RosterPage /> },
      { path: '/dash/BemisSampleRequestForm', element: <BemisSampleRequestFormPage /> },
    ]
  },
  {
    path: '/tsdash',
    element: <TestingLayout />,
    children: [
      { path: '/tsdash/', element: <ACFunctionListPage /> },
      { path: '/tsdash/ProductList', element: <DashHomePage /> },
      { path: '/tsdash/RaceRank', element: <NewRaceRank /> },
      { path: '/tsdash/Setting', element: <ProfileSettingPage /> },
      { path: '/tsdash/UserList', element: <UserListPage /> },
      { path: '/tsdash/GPSReocrd', element: <GPSRecordPage /> },
      { path: '/tsdash/ShopList', element: <ShopListPage /> },
      { path: '/tsdash/EditShop', element: <EditShopPage /> },
      { path: '/tsdash/RosterRecord', element: <RosterPage /> },
      { path: '/tsdash/BemisSampleRequestForm', element: <BemisSampleRequestFormPage /> },
    ]
  },
  {
    path: '/C0533',
    element: <MainLayout />,
    children: [
      { path: '/C0533/', element: <HomePageC0533 /> },
      { path: '/C0533/about-hkrsu', element: <AboutHKRUS /> },
      { path: '/C0533/Our-Crew', element: <OurCrewPage /> },
      { path: '/C0533/course', element: <CoursePage /> },
      { path: '/C0533/Aces', element: <AcesPage /> },
      { path: '/C0533/Course', element: <CoursePage /> },
      { path: '/C0533/performance_team', element: <PerformanceteamPage /> },
      { path: '/C0533/Union_Cup', element: <UnionCupPage /> },
      { path: '/C0533/AWADRS-International', element: <AWADRSInternationPage /> },
      { path: '/C0533/AWARDS', element: <AWARDSPage /> },
      { path: '/C0533/about_rope', element: <SalesPage /> },
      { path: '/C0533/contact-us', element: <ContactUsPage /> },
      { path: '/C0533/Skill_Test', element: <SkillTestPage /> },
    ]
  },
  {
    path: '/C0211',
    element: <C0211MainLayout />,
    children: [
      { path: '/C0211/*', element: < C0211Dashboard/> },
      { path: '/C0211/', element: <C0211Dashboard /> },
      { path: '/C0211/FunctionList', element: <C0211FunctionListPage /> },
      { path: '/C0211/Setting', element: <DashSettingPage /> },
      { path: '/C0211/UserList', element: <C0211UserList /> },
      { path: '/C0211/Product', element: <C0211ProductPage /> },
      { path: '/C0211/TestPage', element: <C0211TestPage /> },
    ]
  },
  {
    path: '/C0217',
    element: <C0217MainLayout />,
    children: [
      { path: '/C0217/*', element: < C0211FunctionListPage/> },
      { path: '/C0217/', element: <C0217Dashboard /> },
      { path: '/C0217/FunctionList', element: <C0217FunctionListPage /> },
      { path: '/C0217/Setting', element: <DashSettingPage /> },
      { path: '/C0217/DownloadReport', element: <C0217DownloadReportPage />},
      { path: '/C0217/TestPage', element: <C0217FunctionListPage /> },
    ]
  },
])

const App = () => {

  useEffect(() => {
    //updateUserData()
  }, [])

  const updateUserData = () => {
    let token = cookies.get('token')
    console.log(token)
    axios.post(`${process.env.REACT_APP_API_URL}/user/GetUserDataByToken`, {}, {
      headers: { Authorization: `Bearer ${token}` }
    })
      .then(async function (response) {
        setUserData(response.data)
        setLoginStatus(true)
      })
      .catch(function (error) {
        //cookies.remove('token')
        //setLoginStatus(false)
        console.log(error);
      });

  }

  const [userData, setUserData] = useState<IUser>({
    ac_id: '',
    user_id: 0,
    user_name: '',
    email_id: '',
    login_count: 0,
    login_email: '',
    user_status: '',
    authority_level: '',
    permissions: []
  });

  const [systemData, setSystemData] = useState<SystemData>({
    isLogin: false,
    FunctionArr: [],
    FuncPermission: []
  });

  const setFunctionArr = (FunctionArr: IFunction[]) =>
    setSystemData(prev => ({ ...prev, FunctionArr }));

  const setLoginStatus = (isLogin: boolean) => 
    setSystemData(prev => ({...prev, isLogin}))
  
  const setFunctionPermission = (FuncPermission: IC0211WebsitePermission[]) =>
    setSystemData(prev => ({...prev, FuncPermission}))
  
  return (
    <UserContext.Provider value={{ userData, setUserData }}>
      <SystemContext.Provider value={{systemData, setLoginStatus, setFunctionArr, setFunctionPermission }}>
        <RouterProvider router={router} />
      </SystemContext.Provider>
    </UserContext.Provider>
  )
}

// const rootElement = document.getElementById('root')
// ReactDOM.render(<RouterProvider router={router} />, rootElement);


const rootElement = document.getElementById('root')
ReactDOM.render(<App />, rootElement);