import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { IC0211ShopBarChart } from '../../../Interface/interface';

import css from '../../../css/C0211/Dashborad.module.css';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const C0211ShopBarChart: React.FC<IC0211ShopBarChart> = ({
    displayDataLabel,
    displayData,
}) => {

    const randomnumber: number[] = [];
    for (let i = 0; i < 100; i++) {
        const randomNum = Math.floor(Math.random() * (83520 - 25200 + 1)) + 25200;
        randomnumber.push(randomNum);
      }
      

    const data = {
        
        labels: displayDataLabel,
        datasets: [
            {
                label: '本月數據',
                data: randomnumber,
                backgroundColor: 'rgba(252, 180, 38, 0.8)', // Bar color
                borderColor: 'rgba(252, 180, 38, 1)', // Border color
                borderWidth: 1,

                barThickness: 30,

                minBarLength: 2,
            },
        ],
    };

    let delayed: boolean;

    const options = {
        responsive: true,
        animation: {
            onComplete: () => {
                delayed = true;
            },
            delay: (context: { type: string; mode: string; dataIndex: number; datasetIndex: number; }) => {
                let delay = 0;
                if (context.type === 'data' && context.mode === 'default' && !delayed) {
                    delay = context.dataIndex * 100 + context.datasetIndex * 100;
                }
                return delay;
            },
        },
        plugins: {
            legend: {
                position: 'bottom' as const,
            },
            title: {
                display: false,
                text: 'Order Raw Data', // Chart title
            },
        },
        scales: {
            y: {
                beginAtZero: true, // Start y-axis at 0
            },
        },
        indexAxis: 'x' as const,
    };

    return (
        <>
            <div className={css.barChart}   >
                <Bar data={data} options={options} height={50}/>
            </div>
        </>
    );
};

export default C0211ShopBarChart;
